import { useRef, useEffect, memo } from 'react';
import { Element } from 'react-scroll';
import { Label, CalendarIcon, TimeslotsCard } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { useMediaScreen } from '../../../hooks';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { TimeslotType } from '../../../types';
import { Day, DayTimeslots } from '../style';

const getFormattedDay = (day: string, language: string) => {
  const dayFormatter = Intl.DateTimeFormat(language, { day: 'numeric' });
  const weekdayFormatter = Intl.DateTimeFormat(language, { weekday: 'short' });
  const monthFormatter = Intl.DateTimeFormat(language, { month: 'short' });

  const date = new Date(day);
  return `${weekdayFormatter.format(date)}, ${dayFormatter.format(date)} ${monthFormatter.format(date)}`;
};

interface TimeslotsDayProps {
  day: string;
  timeslots: TimeslotType[];
  visibleItems: string | undefined;
  setIsVisible: (date: string) => void;
}

const TimeslotsDay = ({ day, timeslots, visibleItems, setIsVisible }: TimeslotsDayProps) => {
  const { isMobile } = useMediaScreen('md');

  const dayRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(dayRef, { threshold: isMobile ? 0.1 : 0.01 });

  const selectedLanguage = getSelectedLanguage();

  const date = getFormattedDay(day, selectedLanguage);

  useEffect(() => {
    document.onwheel = () => {
      if (entry && entry.isIntersecting) {
        !visibleItems?.includes(entry.target.id) && setIsVisible(entry.target.id);
      }
    };
    return () => {
      document.onwheel = null;
    };
  }, [entry]);

  return (
    <Day ref={dayRef} id={day}>
      <Label icon={<CalendarIcon />} size="small" padding="0">
        {date}
      </Label>
      <DayTimeslots>
        {timeslots.map((timeslot: TimeslotType) => (
          <Element key={timeslot.id} name={`timeslot-${timeslot.id}`}>
            <TimeslotsCard
              id={timeslot.id}
              data-timeslot-date={date}
              date={date}
              start={timeslot.start}
              end={timeslot.end}
            />
          </Element>
        ))}
      </DayTimeslots>
    </Day>
  );
};

export default memo(TimeslotsDay);
