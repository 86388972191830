import { useEffect } from 'react';
import { getHost } from '../helpers';

declare const window: {
  interdeal: any;
} & Window;

export const useEqualWeb = () => {
  useEffect(() => {
    const host = getHost();
    if (host.includes('nolink')) {
      const coreCall = document.createElement('script');
      coreCall.src = `${window.interdeal.domains.js}core/5.0.9/accessibility.js`;
      coreCall.defer = true;
      coreCall.integrity =
        'sha512-dxjHZQgpVIG70EQus4+1KR3oj2KGyrtwR/nr4lY+tcMjrQ1Yb9V1SCKNVLGhD3CcPMgANKAqOk4ldI8WWNnQTw==';
      coreCall.crossOrigin = 'anonymous';

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      coreCall.setAttribute('data-cfasync', true);
      document.body.appendChild(coreCall);

      return () => {
        document.body.removeChild(coreCall);
      };
    }
    return undefined;
  }, []);

  return null;
};
