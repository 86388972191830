import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Nullable } from 'tsdef';
import {
  Flex,
  colors,
  Logo,
  Link,
  Avatar,
  Button,
  BellIcon,
  Caption,
  HeartIcon,
  LoginIcon,
  useDropdown,
} from '@beauty/beauty-market-ui';
import { excludeMenuRouteList, getSelectedLanguage } from '../../constants';
import { getFullDate } from '../../helpers/appointments';
import { changeNotificationStatus } from '../../helpers/notifications';
import { cleanUpAfterLogout } from '../../helpers/profile';
import { useMediaScreen } from '../../hooks';
import { useGetNotifications } from '../../hooks/useGetNotifications';
import { useGetProfile } from '../../hooks/useGetProfile';
import { useScrollY } from '../../page/HomePage/hooks/useScrollPosition';
import LogoutSidebar from '../../page/LogoutSidebar/LogoutSidebar';
import { RouterUrl } from '../../routes/routes';
import { useAppSelector } from '../../store/hooks';
import { headerState } from '../../store/redux-slices/headerSlice';
import { selectOrganisation, setShowSearch } from '../../store/redux-slices/organisationSlice';
import { selectUser, updateNotificationStatus } from '../../store/redux-slices/userSlice';
import { NotificationStatus } from '../../types/notifications';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { NotificationsPopup } from '../NotificationsPopup/NotificationsPopup';
import { RatingSidebar, RatingSidebarProps } from '../RatingSidebar/RatingSidebar';
import { Search } from '../Search';
import MenuClient from './MenuClient';
import {
  HeaderWrapper,
  MenuWrapper,
  HeaderGrid,
  BellWrapper,
  CaptionWrapper,
  ButtonsWrapper,
  StyledWrapper,
  StyledBottomSheet,
} from './style';

const Header = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMediaScreen('md');

  const { isLogin, user, activeNotificationId } = useAppSelector(selectUser);
  const { isOnTop, isShowMenu } = useAppSelector(headerState);
  const { isShowSearch: isSearch, isMap } = useAppSelector(selectOrganisation);
  const { notifications } = useAppSelector(selectUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoginPage, setLoginPage] = useState<boolean>(false);
  const [isHomePage, setIsHomePage] = useState<boolean>(true);
  const [isForBusinessPage, setIsForBusinessPage] = useState<boolean>(false);
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState<Nullable<RatingSidebarProps>>(null);

  const {
    ref: bellRef,
    open: isNotificationsPopup,
    setOpen: setNotificationsPopup,
    onClose: closeNotificationsPopup,
  } = useDropdown<Element>();

  const posY = useScrollY();
  useGetProfile();
  useGetNotifications(user.userId);

  const language = getSelectedLanguage();
  const isShowMobileMenu = !excludeMenuRouteList.find(route => location.pathname.includes(route));
  const isFavouritePage = location.pathname.includes(RouterUrl.ClientFavourites);
  const counter = notifications?.new?.filter(notification => !notification.isRead).length;

  const isShowSearch = !isHomePage && !isLoginPage && isDesktop && !isForBusinessPage && isSearch;

  const currentNotification = useMemo(
    () =>
      notifications
        ? [...notifications.new, ...notifications.archive]?.find(item => item.id === activeNotificationId)
        : undefined,
    [notifications, activeNotificationId],
  );

  const loginPress = () => {
    isLogin ? navigate(RouterUrl.LogOut) : navigate(RouterUrl.LoginIntro, { state: { type: 'login' } });
  };

  const signUpPress = () => {
    isLogin ? navigate(RouterUrl.LogOut) : navigate(RouterUrl.LoginIntro, { state: { type: 'signup' } });
  };

  const goHome = () => {
    navigate(RouterUrl.Homepage);
  };

  const handleClick = (navigateTo: string) => {
    isLogin ? navigate(`${navigateTo}/${user.userId}`) : navigate(RouterUrl.Login);
  };

  const onLogout = () => {
    cleanUpAfterLogout(dispatch, navigate);
    setLogoutOpen(false);
  };

  const onLogoutCancel = () => {
    setLogoutOpen(false);
  };

  const loginAnotherUser = () => {
    navigate(RouterUrl.Login);
    setLogoutOpen(false);
  };
  const OnTogglePopup = () => setNotificationsPopup(pr => !pr);

  const notificationsPopupProps = {
    isOpen: isNotificationsPopup,
    title: t('notifications.title'),
    items: notifications?.new ?? [],
    setRead: async (index: number) => {
      if (!notifications.new[index].isRead) {
        const response = await changeNotificationStatus(notifications.new[index].id);
        response?.status === NotificationStatus.VIEWED && dispatch(updateNotificationStatus({ isNew: true, index }));
      }
    },
    setNotificationsPopup,
  };

  useEffect(() => {
    const tempLocation = location.pathname.match('login');
    setLoginPage(!!tempLocation);
    setIsHomePage(location.pathname === RouterUrl.Homepage);
    setIsForBusinessPage(location.pathname === RouterUrl.ForBusiness);
    !location.pathname.includes('organisation') && dispatch(setShowSearch(true));
  }, [location]);

  useEffect(() => {
    if (currentNotification) {
      const { isRead, status, type, id, ...details } = currentNotification;
      const { appointmentId, time, organization, orgAvatar, specialist, specAvatar, specialization, fullAddress } =
        details;
      setAppointmentDetails({
        appointmentId,
        date: getFullDate(time, t, language),
        address: {
          orgName: organization,
          info: fullAddress,
          url: orgAvatar,
        },
        specialist: specialist
          ? {
              orgSpecId: '',
              name: specialist,
              surname: '',
              avatarUrl: specAvatar,
              specialization,
              rating: null,
            }
          : undefined,
      });
    }
  }, [currentNotification]);

  useEffect(() => {
    !activeNotificationId && setAppointmentDetails(null);
  }, [activeNotificationId]);

  return isShowMenu ? (
    <HeaderWrapper
      bgColor={
        isOnTop || (!isHomePage && !isForBusinessPage) || (isMobile && posY > 5) ? colors.white.standard : 'transparent'
      }
    >
      <Flex width="100%" maxWidth="1060px" margin="auto" flexDirection="column" position="relative">
        <HeaderGrid>
          <Logo
            gridArea="logo"
            justifyContent="start"
            labelColor={colors.black.standard}
            logoColor={colors.blue.standard}
            isHideLabel={isMobile}
            monochrome
            onClick={goHome}
            mt="4px"
          />
          <Flex
            gridArea="search"
            justifyItems="center"
            align-items="stretch"
            width="100%"
            justifyContent="center"
            pl="15px"
            pr="15px"
          >
            {isShowSearch && <Search />}
          </Flex>
          <ButtonsWrapper gridArea="navigationMenu">
            {!isForBusinessPage && (
              <Link size="s" design="black" href={RouterUrl.ForBusiness} target="_blank">
                {t('header.forBusiness')}
              </Link>
            )}
            {isLogin && (
              <>
                {isDesktop && (
                  <Link
                    size="s"
                    design="black"
                    href={isFavouritePage ? undefined : `${RouterUrl.ClientFavourites}/${user.userId}`}
                  >
                    <HeartIcon width={16} height={16} />
                  </Link>
                )}
                <BellWrapper ref={isDesktop ? bellRef : null}>
                  <BellIcon onClick={OnTogglePopup} />
                  {!!counter && (
                    <CaptionWrapper onClick={OnTogglePopup}>
                      <Caption large lowline>
                        {counter}
                      </Caption>
                    </CaptionWrapper>
                  )}
                  {isMobile ? (
                    <StyledBottomSheet
                      ref={isMobile ? bellRef : null}
                      isOpen={isNotificationsPopup}
                      content={<NotificationsPopup {...notificationsPopupProps} />}
                      onClose={closeNotificationsPopup}
                      handleClose={closeNotificationsPopup}
                      detent="content-height"
                    />
                  ) : (
                    <NotificationsPopup {...notificationsPopupProps} />
                  )}
                </BellWrapper>
              </>
            )}
            {isLogin && !isLoginPage && isDesktop && !isForBusinessPage && <MenuClient setLogoutOpen={setLogoutOpen} />}
            {!isLogin && !isLoginPage && (
              <StyledWrapper>
                <Link
                  mr="16px"
                  design="blue"
                  icon={!isMobile && <LoginIcon fill="none" />}
                  size="s"
                  onClick={loginPress}
                >
                  {t('header.logIn')}
                </Link>
                <Button size="extraSmall" onClick={signUpPress}>
                  {t('header.signUp')}
                </Button>
              </StyledWrapper>
            )}
            {isLogin && <Avatar size="xs" name={user.name} url={user.avatarUrl} />}
          </ButtonsWrapper>
        </HeaderGrid>
        {isMobile && isShowMobileMenu && !isMap && (
          <MenuWrapper>
            <MobileMenu
              onSearchClick={() => navigate(RouterUrl.Search)}
              onCalendarClick={() => handleClick(RouterUrl.ClientAppointments)}
              onHeartClick={() => handleClick(RouterUrl.ClientFavourites)}
              onProfileClick={() => handleClick(RouterUrl.ClientProfile)}
            />
          </MenuWrapper>
        )}
        {activeNotificationId && appointmentDetails && <RatingSidebar {...appointmentDetails} />}
        <LogoutSidebar
          loginAnotherUser={loginAnotherUser}
          isOpen={isLogoutOpen}
          onLogout={onLogout}
          onLogoutCancel={onLogoutCancel}
        />
      </Flex>
    </HeaderWrapper>
  ) : null;
};

export default Header;
