import { TFunction } from 'react-i18next';
import { NotifyStatus } from '@beauty/beauty-market-ui';
import { AppointmentActions } from '../../types/appointment';
import { UserActions } from '../../types/user';
import { getAppointmentNotifyContent } from './appointment';
import { getUserNotifyContent } from './user';

type NotifyActions = UserActions | AppointmentActions;

export const getNotifyContent = (
  status: NotifyStatus,
  action: NotifyActions,
  t: TFunction<'translation', undefined>,
  message?: string,
) => {
  switch (action) {
    case UserActions.ChangeNotificationLanguage:
    case UserActions.ChangePassword:
    case UserActions.UpdateProfile:
    case UserActions.UpdatePhone:
    case UserActions.RemoveFromFavourites:
    case UserActions.DeleteAccount:
    case UserActions.DisconnectTelegram:
      return getUserNotifyContent(status, action, t, message);
    case AppointmentActions.Create:
    case AppointmentActions.Cancel:
    case AppointmentActions.Update:
    case AppointmentActions.CreateByGuest:
      return getAppointmentNotifyContent(status, action, t, message);
    default:
      return '';
  }
};
