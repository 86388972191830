import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Flex, H2, H7 } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../routes/routes';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/redux-slices/userSlice';
import { StyledButton, TextWrapper, Wrapper } from './style';

export const WhatsappBanner = () => {
  const { t } = useTranslation();

  const { isLogin, user } = useAppSelector(selectUser);
  const navigate = useNavigate();

  const handleConnectClick = () => {
    navigate(isLogin ? `${RouterUrl.ClientProfile}/${user.userId}` : RouterUrl.Login, {
      state: { redirectedFrom: RouterUrl.ClientProfile, isScroll: true },
    });
  };

  return (
    <Wrapper>
      <Flex
        width="100%"
        flexDirection="column"
        gap="24px"
        justifyContent={['space-between', 'space-between', 'space-between', 'start']}
        alignItems={['center', 'center', 'center', 'start']}
      >
        <TextWrapper>
          <H2>{t('home.banner.title')}</H2>
          <H7>{t('home.banner.info')}</H7>
        </TextWrapper>

        <StyledButton onClick={handleConnectClick}>{t('home.banner.button')}</StyledButton>
      </Flex>
    </Wrapper>
  );
};
