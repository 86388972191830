import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Nullable } from 'tsdef';
import { HeroBanner, DropTime } from '@beauty/beauty-market-ui';
import { ShareOrganisationModal } from '../../../components';
import { OurChoiceOrganisations } from '../../../constants';
import { useDroptimeProps } from '../../../hooks/useDroptimeProps';
import { useToggle } from '../../../hooks/useToggle';
import useSetCrumbsQuery from '../../../routes/hooks/useSetCrumbsQuery';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectFavouritesIds } from '../../../store/redux-slices/userSlice';
import { WorkDayType } from '../../../types/organisation';

interface BannerProps {
  organisation: any;
  headline: string;
  title: string;
  onLike: () => void;
  onShare: () => void;
  workSchedule: WorkDayType[];
  rating: Nullable<string>;
  isActive: boolean;
  image?: string;
  reviewsNum?: number;
  photosNum?: number;
}

const Banner = (props: BannerProps) => {
  const { t } = useTranslation();
  const [isShareModalOpen, setShareModalOpen] = useToggle();
  const [organisationId, setOrganisationId] = useState('');
  const { orgId } = useParams();
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setShareModalOpen();
    setOrganisationId(orgId || '');
  };

  const { organisation, headline, workSchedule, onLike, photosNum, reviewsNum, ...rest } = props;

  const dropTimeProps = useDroptimeProps(workSchedule);

  const other = useMemo(
    () => ({
      ...rest,
      reviewsText: t('organisation.reviews', { count: Number(reviewsNum) }),
      photosText: t('organisation.photos', { count: Number(photosNum) }),
      buttonText: t('organisation.bookNow'),
    }),
    [t, rest, orgId],
  );
  const crumbsQuery = useSetCrumbsQuery(other.title);

  const onFavouriteClick = () => onLike();

  return (
    <>
      <HeroBanner
        headline={headline}
        dropTime={<DropTime {...dropTimeProps} close={t('organisation.close')} minWidth="150px" />}
        onFavouriteClick={onFavouriteClick}
        images={[organisation.mainPhoto]}
        {...other}
        onClick={() => navigate(`${RouterUrl.Booking}/${orgId}${crumbsQuery}`, { state: { organisation } })}
        onShareClick={handleModalOpen}
        badgeText={OurChoiceOrganisations.includes(String(orgId)) ? t('organisation.ourChoice') : ''}
      />
      {isShareModalOpen && (
        <ShareOrganisationModal onClose={setShareModalOpen} open={isShareModalOpen} organisationId={organisationId} />
      )}
    </>
  );
};

export default Banner;
