import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button, Flex, InstagramMonoIcon, FacebookMonoIcon, MailFillIcon, ShareIcon } from '@beauty/beauty-market-ui';
import { shareOrganisation } from '../../../helpers/utils';
import { ReversedIcon, UnstrokedIcon } from '../../../hoc';
import { useMediaScreen } from '../../../hooks';
import { PopupSheet } from '../../PopupSheet';

type Props = {
  onClose: () => void;
  open: boolean;
  organisationId: string;
};

export const ShareOrganisationModal = ({ onClose, open, organisationId }: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const shareClick = (sharePlace: string) => {
    onClose();
    shareOrganisation(organisationId, sharePlace);
  };

  const FooterBody = (
    <Flex flexDirection="column" width="100%">
      <Flex justifyContent={isDesktop ? 'space-between' : ''} flexDirection={['column', 'row']} mb="16px" gap="16px">
        <Button size="small" design="secondary" width="100%" onClick={() => shareClick('facebook')}>
          <FacebookMonoIcon />
          Facebook
        </Button>
        <Button design="secondary" size="small" width="100%" onClick={() => shareClick('instagram')}>
          <UnstrokedIcon>
            <InstagramMonoIcon />
          </UnstrokedIcon>
          Instagram
        </Button>
      </Flex>

      <Flex justifyContent={isDesktop ? 'space-between' : ''} flexDirection={['column', 'row']} gap="16px">
        <Button size="small" design="secondary" width="100%" onClick={() => shareClick('email')}>
          <UnstrokedIcon>
            <MailFillIcon />
          </UnstrokedIcon>

          {t('share.email')}
        </Button>
        <Button design="secondary" size="small" width="100%" onClick={() => shareClick('copy')}>
          <ReversedIcon>
            <ShareIcon />
          </ReversedIcon>

          {t('share.link')}
        </Button>
      </Flex>
    </Flex>
  );

  return createPortal(
    <PopupSheet
      open={open}
      onClose={onClose}
      label={t('share.title')}
      descriptor={t('share.subtitle')}
      FooterBody={FooterBody}
    />,
    document.getElementById('root') as HTMLElement,
  );
};
