import { Element } from 'react-scroll';
import styled from 'styled-components';
import { Flex, colors, mediaQueries } from '@beauty/beauty-market-ui';

export const Container = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  width: -webkit-fill-available;
  max-height: auto;
  ${mediaQueries.md} {
    max-height: 800px;
  }
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const Wrapper = styled(Flex)`
  width: calc(100% - 16px);

  [dir='ltr'] & {
    padding-right: 12px;
  }
  [dir='rtl'] & {
    padding-left: 12px;
  }

  max-height: calc(100vh - 224px);
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 104px;
  ${mediaQueries.md} {
    width: 100%;
    position: static;
    height: auto;
    max-height: none;
    overflow-y: visible;
  }
  & > div:first-of-type > div:first-of-type {
    position: sticky;
    margin-top: 126px;
    background-color: ${colors.white.standard};

    ${mediaQueries.md} {
      position: relative;
      top: 0;
      margin-top: 0;
    }
  }
`;

export const StyledElement = styled(Element)`
  :last-child {
    margin-bottom: 110px;
  }
`;

export const DaysContainer = styled(Container)<{ selectedId: string }>`
  gap: 42px;
  min-height: calc(100vh - 420px);

  [id='${props => props.selectedId}'] {
    * {
      color: ${colors.white.standard};
    }
    background-color: ${colors.blue.standard};
  }
`;

export const Day = styled(Flex)`
  gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DayTimeslots = styled(Flex)`
  width: 100%;

  & > div > div {
    width: 100%;
  }

  display: grid !important;
  grid-gap: 8px;

  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 436px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 570px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 710px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 850px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 990px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1080px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const MissingTimeslotsWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 241px;
  gap: 8px;
  text-align: center;
`;

export const CardsWrapper = styled(Flex)`
  height: 100%;
  flex-direction: row;
  width: calc(100vw - 16px);

  ${mediaQueries.md} {
    flex-wrap: wrap;
    width: auto;
  }
  gap: 8px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const DateSliderWrapper = styled(Flex)`
  background-color: ${colors.white.standard};
  z-index: 2;
  height: 158px;
  padding-top: 8px;
  ${mediaQueries.md} {
    height: auto;
    padding-top: 0;
  }
`;
