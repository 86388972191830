import { TFunction } from 'react-i18next';
import { NotifyStatus, NotifyPropsType } from '@beauty/beauty-market-ui';
import { UserActions } from '../../types/user';

const successTitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.title.success',
  [UserActions.ChangePassword]: 'profile.alerts.title.changePasswordSuccess',
  [UserActions.UpdateProfile]: 'profile.alerts.title.editProfileSuccess',
  [UserActions.UpdatePhone]: 'profile.alerts.title.editPhoneSuccess',
  [UserActions.RemoveFromFavourites]: 'profile.alerts.title.removeFromFavouritesSuccess',
  [UserActions.DisconnectTelegram]: 'profile.alerts.title.disconnectTelegramSuccess',
  [UserActions.DeleteAccount]: 'profile.alerts.title.deleteAccountSuccess',
};

const errorTitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.title.error',
  [UserActions.ChangePassword]: 'alerts.title.error',
  [UserActions.UpdateProfile]: 'profile.alerts.title.editProfileError',
  [UserActions.UpdatePhone]: 'profile.alerts.title.editPhoneError',
  [UserActions.RemoveFromFavourites]: 'profile.alerts.title.removeFromFavouritesError',
  [UserActions.DisconnectTelegram]: 'profile.alerts.title.disconnectTelegramError',
  [UserActions.DeleteAccount]: 'profile.alerts.title.deleteAccountError',
};

const successSubtitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.subtitle.informationUpdated',
  [UserActions.ChangePassword]: 'profile.alerts.subtitle.changePasswordSuccess',
  [UserActions.UpdateProfile]: 'profile.alerts.subtitle.editProfileSuccess',
  [UserActions.UpdatePhone]: 'profile.alerts.subtitle.editPhoneSuccess',
  [UserActions.RemoveFromFavourites]: 'profile.alerts.subtitle.removeFromFavouritesSuccess',
  [UserActions.DisconnectTelegram]: 'profile.alerts.subtitle.disconnectTelegramSuccess',
  [UserActions.DeleteAccount]: 'profile.alerts.subtitle.deleteAccountSuccess',
};

const errorSubtitles = {
  [UserActions.ChangeNotificationLanguage]: 'alerts.subtitle.informationNotUpdated',
  [UserActions.ChangePassword]: 'alerts.subtitle.informationNotUpdated',
  [UserActions.UpdateProfile]: 'profile.alerts.subtitle.editProfileError',
  [UserActions.UpdatePhone]: 'profile.alerts.subtitle.editPhoneError',
  [UserActions.RemoveFromFavourites]: 'profile.alerts.subtitle.removeFromFavouritesError',
  [UserActions.DisconnectTelegram]: 'profile.alerts.subtitle.disconnectTelegramError',
  [UserActions.DeleteAccount]: 'profile.alerts.subtitle.deleteAccountError',
};

export const getUserNotifyContent = (
  status: NotifyStatus,
  action: UserActions,
  t: TFunction<'translation', undefined>,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
