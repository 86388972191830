import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookiesAccepted } from '../../api/api.helpers';
import { CookieModal } from '../../components/CookieModal/CookieModal';
import { Search } from '../../components/Search';
import { WhatsappBanner } from '../../components/WhatsappBanner/WhatsappBanner';
import { clearBooking } from '../../constants';
import { useMediaScreen } from '../../hooks';
import { useGeolocation } from '../../hooks/useGeolocation';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateIsOnTop } from '../../store/redux-slices/headerSlice';
import { setFrom } from '../../store/redux-slices/historySlice';
import { searchState, updateSearchPosition } from '../../store/redux-slices/searchSlice';
import { updateBooking } from '../../store/redux-slices/userSlice';
import { CRMPreviewSection } from './components/CRMPreviewSection';
import { MobilePreviewSection } from './components/MobilePreviewSection';
import { OrderSection } from './components/OrderSection';
import { OrganisationsSection } from './components/OrganisationsSection';
// import { StatisticsPromoSection } from './components/StatisticsPromoSection';
import { ReviewsBlock } from './components/ReviewsBlock';
import { TodayStatisticsSection } from './components/TodayStatisticsSection';
import { useScrollY } from './hooks/useScrollPosition';
import { ColumnFlex, HomeWrapper, SearchInputWrapper, SearchWrapper, StyledDisplay } from './style';

const Home = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen('md');

  const { isSearchInMain } = useAppSelector(searchState);

  const dispatch = useAppDispatch();
  const posY = useScrollY();
  useGeolocation();

  const searchRef = useRef<HTMLElement>();
  const titleRef = useRef<HTMLElement>();

  const isCookieAccepted = getCookiesAccepted();

  useEffect(() => {
    const curSearchPos = searchRef.current?.getBoundingClientRect();

    if (posY > 10) dispatch(updateIsOnTop({ isOnTop: true }));
    else dispatch(updateIsOnTop({ isOnTop: false }));
    if (curSearchPos!.y < 16) dispatch(updateSearchPosition({ isSearchInMain: false }));
    else dispatch(updateSearchPosition({ isSearchInMain: true }));
  }, [posY, dispatch]);

  useEffect(() => {
    dispatch(setFrom('Home'));
    dispatch(updateBooking(clearBooking));
  }, []);

  return (
    <HomeWrapper>
      <ColumnFlex width="100%">
        <ColumnFlex alignItems="center">
          <SearchWrapper ref={titleRef}>
            <StyledDisplay>{t('home.title')}</StyledDisplay>
          </SearchWrapper>

          <SearchInputWrapper ref={searchRef}>{(isSearchInMain || isMobile || true) && <Search />}</SearchInputWrapper>

          <TodayStatisticsSection />
        </ColumnFlex>
        {!isCookieAccepted && <CookieModal />}
        <OrganisationsSection />
        <OrderSection />
        <WhatsappBanner />
        {/* <StatisticsPromoSection isMobile={isMobile} /> */}
        <ReviewsBlock />
        <CRMPreviewSection />
        <MobilePreviewSection />
      </ColumnFlex>
    </HomeWrapper>
  );
};

export default Home;
