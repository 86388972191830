import styled, { css } from 'styled-components';
import {
  Div,
  Flex,
  BodySmall,
  colors,
  mediaQueries,
  Label,
  ArrowLeftIcon,
  Button,
  DataList,
} from '@beauty/beauty-market-ui';
import { croppedText } from '../../style';

export const StatePanelWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${mediaQueries.md} {
    width: 290px;
    & > div {
      position: sticky;
      top: 144px;
      & > div {
        gap: 8px;
        & > div * {
          text-align: start !important;
        }
      }
    }
  }
`;

export const SelectPanelWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow: visible;
  ${mediaQueries.md} {
    overflow: hidden;
  }
`;

export const StatePanelInfo = styled(Flex)`
  flex-direction: column;
  margin-top: 8px;
  padding-top: 16px;
  gap: 40px;
`;

export const GreyBodySmall = styled(BodySmall)`
  && {
    color: ${props => props.theme.colors.grey.dark};
  }
  white-space: pre;
`;

export const AddCalendar = styled(BodySmall)`
  cursor: pointer;
  && {
    color: ${props => props.theme.colors.blue.standard};
  }
`;

export const AccountLogInWrapper = styled(Flex)`
  flex-direction: column;
  padding: 24px 16px;
  gap: 16px;
  background-color: ${colors.grey.light};
  border-radius: 12px;
`;

export const CardsWrapper = styled(Div)`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-content: space-between;
  align-items: start;
  gap: 8px;
`;

export const BookingWrapper = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  width: 100%;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: 40px;
  }
`;

export const StyledLabel = styled(Label)`
  :hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled(Flex)<{ isLogin: boolean }>`
  flex-direction: column;
  & > div:first-child {
    box-shadow: none !important;
    div:nth-child(2) {
      padding: 0 !important;
    }
  }

  ${({ isLogin }) =>
    !isLogin &&
    css`
      height: 100%;
      justify-content: space-between;
    `}
`;

export const PhoneWrapper = styled(Flex)`
  width: 100%;
  margin-left: 8px;
  input,
  div {
    width: 100%;
  }
`;

export const StyledFlex = styled(Flex)`
  margin-bottom: 16px;
  flex-direction: ${({ theme }) => (theme.rtl ? 'row-reverse' : 'row')};
`;

export const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  ${({ theme }) => theme.rtl && 'transform: scaleX(-1)'};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  min-width: 228px;
  height: 56px;
`;

export const StyledDataList = styled(DataList)`
  min-width: 58% !important;
  & > div > div:not(:first-child) {
    width: 100%;
    div,
    p {
      width: 100%;
      display: inline-block;
      ${croppedText};
      border-radius: 0;
    }
  }
  & > div:last-child {
    max-width: 30%;
    & > div {
      width: max-content;
    }
  }
  & > div:first-child {
    width: 64%;
    ${mediaQueries.md} {
      width: 70%;
    }
  }
`;
