import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { has } from 'lodash';
import { Button } from '@beauty/beauty-market-ui';
import { setAccessToken } from '../../../api/api.helpers';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { FormikPasswordInput } from '../../../components/functional/formik/formik-password-input/FormikPasswordInput';
import { InvalidVariants } from '../../../constants';
import { redirectAfterLogin } from '../../../helpers';
import { loginByEmail } from '../../../helpers/loginByEmail';
import { useFocus } from '../../../hooks/useFocus';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { setIsCurrentLocation } from '../../../store/redux-slices/searchSlice';
import { updateUser } from '../../../store/redux-slices/userSlice';
import {
  LoginByEmailForm,
  initialByEmailValues,
  loginEmailFormValidationSchema,
  LoginByEmailFields,
} from '../Login.definitions';

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isWrongPassword, setWrongPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const formRef = useFocus();

  const onFormSubmit = useCallback(
    async (data: LoginByEmailForm) => {
      setLoading(true);
      const response = await loginByEmail(data);
      if (response.success) {
        setInvalidType(null);
        setWrongPassword(false);
        const { email, name, surname, code, number, id, avatarUrl, idNumber } = response.data;
        setAccessToken(response.token); // TODO Review after BE response
        dispatch(
          updateUser({
            isLogin: true,
            user: { name, surname, email, code, number, userId: id, avatarUrl, idNumber },
          }),
        );

        !has(state, 'redirectedFrom') && dispatch(setIsCurrentLocation(true));
        redirectAfterLogin(navigate, { ...state, id });
      } else
        switch (response.statusCode) {
          case 401:
            setWrongPassword(true);
            break;
          case 404:
            setInvalidType(InvalidVariants.NotFound);
            break;
          default:
            setInvalidType(InvalidVariants.Unknown);
        }
      setLoading(false);
    },
    [navigate, dispatch, state, invalidType, isWrongPassword, t],
  );

  const formikContextValue = {
    initialValues: initialByEmailValues,
    validationSchema: loginEmailFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid }) => (
        <Form ref={formRef}>
          <FormikInput
            autoFocus
            id={LoginByEmailFields.Email}
            name={LoginByEmailFields.Email}
            placeholder="Email"
            invalidType={invalidType}
            onInput={() => {
              setInvalidType(null);
              setWrongPassword(false);
            }}
          />
          <FormikPasswordInput
            mt="8px"
            id={LoginByEmailFields.Password}
            name={LoginByEmailFields.Password}
            placeholder={t('registration.password')}
            secondaryCaption={t('login.resetPassword')}
            onSecondaryCaptionClick={() => navigate(RouterUrl.ResetPassword)}
            onInput={() => {
              setInvalidType(null);
              setWrongPassword(false);
            }}
            isWrong={isWrongPassword}
          />
          <Button
            disabled={!isValid || invalidType || isWrongPassword || isLoading}
            design="primary"
            mt="24px"
            width="100%"
            size="large"
            type="submit"
          >
            {t(`login.continue`)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
