import styled, { css } from 'styled-components';
import {
  BodySmall,
  Button,
  CheckIcon,
  colors,
  Div,
  Flex,
  mediaQueries,
  Slider,
  ShareButton,
  Review,
} from '@beauty/beauty-market-ui';
import { Container } from '../../components';
import { zIndex } from '../../constants';
import { getSliderMargin } from '../../helpers/rtl';
import { croppedText } from '../../style';

export const ContentWrapper = styled(Div)`
  position: relative;
  z-index: ${zIndex.organisationContentWrapper};
`;

export const AboutWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  ${mediaQueries.md} {
    overflow-x: auto;
  }
`;

export const AboutAdvantages = styled(Flex)`
  margin-top: 32px;
  gap: 12px;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  ${mediaQueries.md} {
    margin-top: 40px;
    gap: 26px;
    flex-direction: row;
    align-items: center;
  }
`;

export const AboutAdvantagesItem = styled(Flex)`
  gap: 6px;
  justify-content: flex-start;
  align-items: center;
`;

export const Description = styled(Flex)`
  margin-top: 16px;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;

  p {
    margin-top: 0;
  }
`;

export const RatingWrapper = styled(Flex)`
  width: 100%;

  ${mediaQueries.md} {
    width: 290px;
  }

  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
`;

export const ReviewsWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
`;

export const ReviewsList = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;
`;

export const ShowMoreButton = styled(Button)`
  align-self: center;
`;

export const OfferingsWrapper = styled(Flex)`
  margin-top: 85px;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;

  ${mediaQueries.md} {
    margin-top: 160px;
  }
`;

export const GreyBodySmall = styled(BodySmall)`
  && {
    color: ${props => props.theme.colors.grey.dark};
  }
`;

export const OldPrice = styled(GreyBodySmall)`
  text-decoration: line-through;
`;

export const ContactAndAboutWrapper = styled(Flex)`
  width: 100%;
  margin-top: 64px;
  gap: 60px;
  align-items: flex-start;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      flex-direction: row;
      gap: 40px;
      margin-top: 40px;
    }
  `}
`;

export const AdditionalContentWrapper = styled(Flex)`
  flex-direction: column;
  margin-top: 160px;
  margin-bottom: 160px;
  gap: 80px;

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      gap: 160px;
    }
  `}
`;

export const RatingAndRewiewsWrapper = styled(Flex)`
  width: 100%;
  gap: 60px;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      flex-direction: row;
    }
  `}
`;

export const StyledContainer = styled(Container)`
  width: 100%;
  max-width: 1280px;
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;

  div:nth-child(2) button {
    margin-right: 4px;
  }

  & > div {
    max-width: 100%;
  }

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      position: relative;
      bottom: 40px;
      padding-top: 24px;
    }
  `}
`;

export const SpecialistButtonsWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 0;
  box-sizing: border-box;
`;

export const PortfolioImage = styled.img`
  width: 104px;
  height: 104px;
  border-radius: 12px;
`;

export const GalleryImage = styled.img`
  object-fit: cover;
  border-radius: 10px;
  width: 170px;
  height: 120px;

  :hover {
    cursor: pointer;
  }
`;

export const FullImageWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
`;

export const ShadowedFlex = styled(Flex)`
  width: 100%;
  height: 100vh;
  z-index: 6;
  position: absolute;
  top: 0;
  opacity: 0.4;
  background-color: ${colors.black.standard};
`;

export const FullSizeImage = styled.img`
  position: absolute;
  object-fit: cover;
  z-index: 6;
  opacity: 1;
  border-radius: 10px;

  width: auto;
  max-width: 100vw;
  height: fit-content;
  max-height: calc(100vh - 152px);

  :hover {
    cursor: pointer;
  }
`;

export const ShowMoreWrapper = styled(Flex)`
  flex-direction: column;
  align-items: start;
  overflow: hidden;
`;

export const ShowButton = styled(Button)`
  color: ${colors.blue.standard} !important;
  padding: 16px 0 0 !important;
`;

export const BadgesWrapper = styled(Flex)`
  flex-direction: row;
  gap: 16px;

  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      flex-direction: column;
    }
  `}

  img {
    width: 28px;
    height: 28px;
  }
`;

export const BadgeItemContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  background-color: ${colors.white.standard};
  gap: 8px;
  box-sizing: border-box;

  div:first-of-type {
    border: 1px solid ${colors.grey.standard};
  }
`;

export const DropdownHeaderWrapper = styled(Flex)`
  width: fit-content;
  min-width: 90px;
  max-width: 164px;
  gap: 4px;
  height: 32px;
  padding: 4px 16px;
  box-sizing: border-box;
  border-radius: 30px;
  align-items: center;
  justify-content: space-between;

  :hover,
  * :hover {
    cursor: pointer;
  }
`;

export const IconWrapper = styled(Flex)<{ stroke: string }>`
  width: 16px;
  box-sizing: border-box;

  svg path {
    ${({ stroke }) => `stroke: ${stroke};`}
  }
`;

export const OptionsWrapper = styled(Flex)`
  position: absolute;
  z-index: 2;
  top: 36px;
  flex-direction: column;
  width: 184px;
  border-radius: 10px;
  gap: 4px;
  padding: 4px;
  box-sizing: border-box;
  background-color: ${colors.white.standard};
  box-shadow:
    0 0 0 1px rgba(10, 9, 11, 0.05),
    0 2px 2px -1px rgba(10, 9, 11, 0.04),
    0px 2px 12px -1px rgba(10, 9, 11, 0.1);
`;

export const OptionWrapper = styled(Flex)<{ isSelected: boolean }>`
  gap: 8px;
  padding: 18px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;

  :hover {
    cursor: pointer;
    background-color: ${colors.grey.light};
  }

  ${({ isSelected }) => isSelected && `background-color: ${colors.grey.light};`}
`;

export const ReviewsBodyWrapper = styled(Flex)`
  width: 100%;
  & > div:first-child {
    width: 100%;
  }
`;

export const CheckIconStyled = styled(CheckIcon)`
  width: 16px;
  height: 16px;
  path {
    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const SliderWrapper = styled(Flex)`
  width: 100%;
  & > div:first-of-type {
    width: 100%;
    & > div:first-of-type {
      display: none;
    }
  }
  & > div:first-of-type > div:nth-of-type(2) > div > div > div {
    width: auto;
  }
`;

export const FilterButton = styled(Button)`
  padding: 0 !important;
`;

export const TextWrapper = styled(Flex)`
  flex-direction: column;
  overflow-x: auto;
  * {
    ${croppedText};
  }
`;

export const SpecOptionWrapper = styled(Flex)`
  width: 85%;
  height: 56px;
  padding: 8px 0;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  gap: 8px;

  div:first-child > div {
    padding: 0;
  }
`;

export const CutBodySmall = styled(BodySmall)<{ height: string }>`
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: 'ellipsis';
  white-space: pre-line;
  height: ${({ height }) => height};

  width: 85vw;
  ${({ theme }) => css`
    ${theme.mediaQueries.md} {
      width: 400px;
    }
  `}
`;

export const StyledSlider = styled(Slider)`
  .swiper {
    ${({ theme }) => getSliderMargin(theme.rtl)};
  }
`;

export const StyledShareButton = styled(ShareButton)`
  ${({ theme }) => theme.rtl && 'transform: scaleX(-1)'};
`;

export const StyledReview = styled(Review)`
  & > div {
    width: 100%;
  }
`;

export const SpecInfo = styled(Flex)`
  flex-direction: column;
  justify-content: end;
  align-self: center;
  max-width: 240px;
  ${({ theme }) => css`
    ${theme.rtl ? 'margin-right: 16px' : 'margin-left: 16px'}
  `}
`;

export const EducationInfo = styled(Flex)`
  flex-direction: column;
  ${({ theme }) => css`
    ${theme.rtl ? 'margin-right: 8px' : 'margin-left: 8px'}
  `}
`;

export const StarsWrapper = styled(Flex)`
  align-items: center;
  p {
    margin-right: 8px;
    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 8px;
    }
  }
`;
