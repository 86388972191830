import { useEffect, useState } from 'react';
import { range } from 'lodash';
import { fetchAvailableTimeslots } from '../helpers/organisation';
import { TimeslotsType } from '../types';
import { TimeslotsRequest } from '../types/timeslot';
import { modifyTimeslotsWithId } from './helpers';

export const useGetTimeslots = (
  days: number,
  maxNumberOfDays: number,
  timezone: string,
  serviceId?: string,
  orgSpecId?: string,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [timeslots, setTimeslots] = useState<TimeslotsType | null>(null);

  const currentDate = new Date();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTimeslots = async () => {
      setIsLoading(true);
      let slots = {} as TimeslotsType;
      range(0, maxNumberOfDays).map(num => {
        slots[new Date(new Date().setDate(currentDate.getDate() + num)).toISOString().substring(0, 10)] = null;
        return null;
      });

      let currentDaysOffset = 0;
      let isMoreFetch = true;
      while (!abortController.signal.aborted && isMoreFetch && currentDaysOffset < maxNumberOfDays) {
        // eslint-disable-next-line no-await-in-loop
        const response: TimeslotsRequest = await fetchAvailableTimeslots(serviceId as string, {
          date: new Date(new Date().setDate(currentDate.getDate() + currentDaysOffset)).toISOString(),
          days,
          timezone,
          orgSpecId,
        });

        if (!abortController.signal.aborted) {
          slots = modifyTimeslotsWithId(response, slots);
          if (Object.entries(slots)?.findIndex(([date, slot]) => slot && slot.length) > -1) {
            isMoreFetch = false;
            setTimeslots(slots);
          } else currentDaysOffset += days;
        }
      }
      currentDaysOffset >= maxNumberOfDays && setTimeslots(slots);
      setIsLoading(false);
    };

    !abortController.signal.aborted && serviceId && fetchTimeslots();

    return () => {
      abortController.abort();
    };
  }, [serviceId, days, maxNumberOfDays, timezone, orgSpecId]);

  return {
    isLoading,
    timeslots,
  };
};
