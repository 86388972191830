import { TFunction } from 'react-i18next';
import { scroller } from 'react-scroll';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CountryCode } from 'libphonenumber-js';
import _, { isArray, isNil, omitBy } from 'lodash';
import { Nullable } from 'tsdef';
import { Link } from '@beauty/beauty-market-ui';
import { DollarIcon, EuroIcon, ShekelIcon } from '../assets';
import {
  CRM_PRED_URL,
  CRM_TEST_URL,
  CRM_URL,
  Currency,
  INITIAL_DAYS_LOADED,
  InvalidVariants,
  OurChoiceOrganisations,
  regionListCode,
} from '../constants';
import { useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { ApiAction, Text, TextWithTranslations, TimeslotsType } from '../types';

export const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length }, (__, idx) => idx + start);
};

export const formatDate = (_date: string) => {
  const date = new Date(_date);
  const [number, month, year] = [date.getDate(), date.getMonth() + 1, date.getFullYear()];
  return `${number < 10 ? `0${number}` : number}.${month < 10 ? `0${month}` : month}.${year}`;
};

export const getLocations = () => ['Brest', 'Wroclaw', 'Munich', 'Oslo', 'Kiev'];

export const getHost = () => window.location.origin.replace('www.', '');

export const getFullOrganisationLink = (id: string, query = '', exception = true) => {
  const url = getHost();
  return exception ? `${url}/organisation/${id}${query}` : '';
};

export const openLink = (id: string, query?: string) => {
  const link = getFullOrganisationLink(id);
  window.open(link + (query || ''), '_blank');
};

export const scrollUp = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const shareOrganisation = (id: string, sharePlace: string, email?: string) => {
  const link = getFullOrganisationLink(id);
  let emailLink = '';
  switch (sharePlace) {
    case 'copy':
      return navigator.clipboard.writeText(link);
    case 'facebook':
      return window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`, '_blank');
    case 'email':
      emailLink = `mailto:${email}?body=${encodeURIComponent(link)}`;
      window.location.href = emailLink;
      return '';
    case 'instagram':
      return window.open(`https://www.instagram.com/?url=${encodeURIComponent(link)}`, '_blank');
    case 'telegram':
      return window.open(
        `https://t.me/share?url=${encodeURIComponent(link)}&text=${encodeURIComponent(link)}`,
        '_blank',
      );
    case 'whatsapp':
      return window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`, '_blank');
    default:
      return '';
  }
};

export const setLink = (href: string, size = 's', isBlank = false) => (
  <Link size={size} display="inline" href={href} target={isBlank ? '_blank' : '_self'} />
);

export const setImgUrl = (urls: string[]) => {
  const dpr = Math.round(window.devicePixelRatio);
  return urls.length > dpr ? urls[dpr - 1] : urls[urls.length - 1];
};

const pickTranslationByLocale = (translations: Text[], language: string) => {
  const translation = _.head(_.values(_.pickBy(translations, { langId: language })));
  return translation?.text || null;
};

export const getTranslation = (title: TextWithTranslations, language: string) => {
  if (!title) return '';
  const defaultText = title.text;
  const isTranslationExists = _.get(title, 'translation', []).length > 0;
  const translation = isTranslationExists && pickTranslationByLocale(title.translation, language);
  return translation || defaultText;
};

export const getInvalidType = (errorCode: number, type: ApiAction) => {
  switch (errorCode) {
    case 400: {
      switch (type) {
        case 'changeEmail':
        case 'signUp':
          return InvalidVariants.Email;
        case 'changePhone':
        case 'registration':
          return InvalidVariants.InvalidPhone;
        case 'codeVerification':
        case 'login':
          return InvalidVariants.ExpiredOTP;
        default:
          return InvalidVariants.Unknown;
      }
    }
    case 401:
      return InvalidVariants.InvalidOTP;
    case 404:
      return InvalidVariants.NotFound;
    case 409:
      return InvalidVariants.Exists;
    case 429:
      return InvalidVariants.TooMany;
    case 500:
      return InvalidVariants.MaxAttempts;
    default:
      return InvalidVariants.Unknown;
  }
};

export const openCrmSignup = () => {
  const host = getHost();
  const url = (() => {
    if (host.includes('test')) return `${CRM_TEST_URL}/login/signup?isLogin=false`;
    if (host.includes('dev')) return `${CRM_PRED_URL}/login/signup?isLogin=false`;
    return `${CRM_URL}/login/signup?isLogin=false`;
  })();
  window.open(url, '_blank');
};

export const getListRegionCode = () => regionListCode;

export const getShortCountryByCode = (code?: string): CountryCode =>
  (regionListCode.find(item => item.code === code)?.short as CountryCode) ?? '';

export const sortByOurChoice = items =>
  isArray(items) ? [...items].sort(item => (OurChoiceOrganisations.includes(item.id) ? -1 : 1)) : [];

export const toFormData: (objectData: object, withNull?: boolean) => FormData = (objectData, withNull = false) => {
  const formData = new FormData();
  Object.entries(withNull ? objectData : omitBy(objectData, isNil)).map(el => {
    if (Array.isArray(el[1])) {
      for (const file of el[1]) {
        if (file instanceof File) formData.append('photos', file);
      }
    } else formData.append(el[0], el[1]);
    return 1;
  });
  return formData;
};

export const checkPhotosValid = (photos, t: TFunction<'translation', undefined>, maxCount = 5): string | null => {
  const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/png'];

  for (const photo of photos) {
    if (photo instanceof File) {
      if (!validTypes.includes(photo.type)) {
        return t('validation.incorrectImageFormat');
      }

      const size = photo.size / (1024 * 1024);
      if (size > 6) {
        return t('validation.sizeLess6');
      }
    }
  }

  if (photos.length > maxCount) {
    return useDynamicTranslation('validation.photosLessMax', { max: maxCount });
  }
  if (photos.length === maxCount) {
    return null;
  }
  return '';
};

export const scrollTo = (toId: string, containerId: string, isHorizontal?: boolean) => {
  scroller.scrollTo(toId, {
    duration: 250,
    delay: 0,
    smooth: true,
    containerId,
    horizontal: isHorizontal,
  });
};

export const getCurrencySymbol = (currency: Nullable<string>) => {
  if (!currency) return '';

  const formatter = Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency,
  });

  const parts = formatter.formatToParts(0);
  const symbol = parts.find(part => part.type === 'currency')?.value;
  return symbol || '';
};

export const getCurrencyIcon = (currency: string) => {
  switch (currency) {
    case Currency.USD:
      return <DollarIcon />;
    case Currency.EUR:
      return <EuroIcon />;
    case Currency.ILS:
      return <ShekelIcon />;
    default:
      return getCurrencySymbol(currency);
  }
};

export const getCurrencyOptions = t => [
  {
    id: Currency.ILS,
    value: t(`profile.currency.${Currency.ILS}`),
  },
  {
    id: Currency.USD,
    value: t(`profile.currency.${Currency.USD}`),
  },
  {
    id: Currency.EUR,
    value: t(`profile.currency.${Currency.EUR}`),
  },
  // {
  //   id: Currency.BYN,
  //   value: t(`profile.currency.${Currency.BYN}`),
  // },
  // {
  //   id: Currency.GEL,
  //   value: t(`profile.currency.${Currency.GEL}`),
  // },
  // {
  //   id: Currency.PLN,
  //   value: t(`profile.currency.${Currency.PLN}`),
  // },
  // {
  //   id: Currency.RUB,
  //   value: t(`profile.currency.${Currency.RUB}`),
  // },
  // {
  //   id: Currency.UAH,
  //   value: t(`profile.currency.${Currency.UAH}`),
  // },
  // {
  //   id: Currency.GBP,
  //   value: t(`profile.currency.${Currency.GBP}`),
  // },
];

export const getTranslatedString = (language: string, arg?: TextWithTranslations[] | TextWithTranslations) => {
  if (Array.isArray(arg)) {
    return arg.map(item => getTranslation(item, language)).join(', ');
  }
  return arg ? getTranslation(arg, language) : '';
};

export const getStartDate = (slots: TimeslotsType, date: string, isDescent: boolean) => {
  const indexOfDate = Object.entries(slots).findIndex(slot => slot[0] === date);
  const cutSlots = isDescent
    ? Object.entries(slots).slice(0, indexOfDate)
    : Object.entries(slots).slice(indexOfDate, Object.entries(slots).length);

  if (isDescent) {
    const reversedSlots = [...cutSlots].reverse();
    const startIndex = reversedSlots.findIndex(slot => slot[1] === null);
    let index = startIndex;
    let counter = 1;
    while (reversedSlots[index][1] === null && counter < INITIAL_DAYS_LOADED - 2 && index < reversedSlots.length) {
      index += 1;
      counter += 1;
    }
    return startIndex > -1 ? reversedSlots[index][0] : null;
  }
  return cutSlots.findIndex(slot => slot[1] === null) > -1
    ? cutSlots[cutSlots.findIndex(slot => slot[1] === null)][0]
    : null;
};

export const getFullName = (person?: { name?: string; surname?: string }) =>
  person ? [person?.name, person?.surname].join(' ') : '';

export const hasScroll = () => document.body.scrollHeight > window.innerHeight;
