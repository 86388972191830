import styled from 'styled-components';
import { Flex, colors, mediaQueries, Button } from '@beauty/beauty-market-ui';
import Banner from '../../assets/whatsapp_banner.png';
import BannerRtl from '../../assets/whatsapp_banner_rtl.png';
import BannerMobile from '../../assets/whatsapp_mobile.png';

export const Wrapper = styled(Flex)`
  border-radius: 15px;
  padding: 42px 25px 24px 25px;
  box-sizing: border-box;

  margin: 80px auto;

  aspect-ratio: 0.68;
  min-height: 480px;

  background-repeat: no-repeat;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 71, 59, 1),
      rgba(255, 71, 59, 0) 36%,
      transparent 70%,
      rgba(255, 71, 59, 0) 74%,
      rgba(255, 71, 59, 1)
    ),
    url(${BannerMobile});
  background-size: cover;
  background-position: center;
  max-width: 420px;

  ${mediaQueries.md} {
    margin: 160px 0 80px 0;
    aspect-ratio: 3.79;
    width: 100%;
    max-width: 100%;
    min-height: auto;
    padding: 58px 0 0 107px;
    background-size: contain;

    background-image: linear-gradient(to right, rgba(255, 71, 59, 1) 35%, rgba(255, 71, 59, 0) 60%), url(${Banner});
    background-position: right;

    [dir='rtl'] & {
      background-image: linear-gradient(to left, rgba(255, 71, 59, 1) 35%, rgba(255, 71, 59, 0) 60%), url(${BannerRtl});
      background-position: left;
      padding: 58px 107px 0 0;
    }
  }

  h2,
  h6 {
    color: ${colors.white.standard};
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  background-color: ${colors.black.standard}!important;
  font-weight: 500 !important;
  border: none !important;

  ${mediaQueries.md} {
    width: fit-content;
  }
`;

export const TextWrapper = styled(Flex)`
  gap: 24px;
  flex-direction: column;
  @media (max-width: 992px) {
    gap: 16px;
    * {
      text-align: center;
    }
  }
`;
