import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface HistoryState {
  paths: string[];
  from: string;
  organisation: {
    name: string;
    path: string;
  };
  topCategory: string;
  offersView: string; // ! Theis stete is not used, but I didn't delete it because I'm not sure it won't be useful anymore.
}

const initialState: HistoryState = {
  from: '',
  paths: [],
  organisation: {
    name: '',
    path: '',
  },
  topCategory: 'All',
  offersView: 'list',
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    updatePaths: (state, action: PayloadAction<string>) => {
      state.paths = [...state.paths, action.payload];
    },
    setOrganisation: (state, action: PayloadAction<{ name: string; path: string }>) => {
      state.organisation = { ...action.payload };
    },
    setTopCategory: (state, action: PayloadAction<string>) => {
      state.topCategory = action.payload;
    },
    setView: (state, action: PayloadAction<string>) => {
      state.offersView = action.payload;
    },
    setFrom: (state, action: PayloadAction<string>) => {
      state.from = action.payload;
    },
    GoBack: state => {
      const pathsHistory = state.paths;
      pathsHistory.pop();
      pathsHistory.pop();
      state.paths = pathsHistory;
    },
    resetHistory: () => initialState,
  },
});

export const { GoBack, updatePaths, setOrganisation, setTopCategory, setView, setFrom, resetHistory } =
  historySlice.actions;

export const selectHistory = (state: RootState) => state.history;
export const selectTopCategory = (state: RootState) => state.history.topCategory;

export default historySlice.reducer;
