import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Nullable } from 'tsdef';
import { Flex, H3, useNotify, NotifyStatus } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../../constants';
import { getNotifyContent } from '../../../../../helpers';
import { getTranslation } from '../../../../../helpers/utils';
import { useMediaScreen } from '../../../../../hooks';
import { useGetNotificationTypes } from '../../../../../hooks/useGetNotificationTypes';
import { useGetProfile } from '../../../../../hooks/useGetProfile';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectUser, ThunkUser, updateTelegram } from '../../../../../store/redux-slices/userSlice';
import { ClientNotificationType, UserActions } from '../../../../../types';
import { isTelegramNotification } from '../../helpers';
import { NotificationItem } from './NotificationItem';
import { TelegramPopup } from './TelegramPopup';

export const Notifications = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const { user, profile } = useAppSelector(selectUser);

  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const [isPopup, setPopup] = useState(false);
  const [params, setParams] = useState<Nullable<{ id: string; type: string; enabled: boolean }>>(null);
  const [telegramType, setTelegramType] = useState<'appointment' | 'marketing' | null>(null);

  const ref = useRef<Nullable<Element>>(null);

  const { data, types } = useGetNotificationTypes();
  // need for pooling telegram
  const { isPolling, setPolling } = useGetProfile();

  const language = getSelectedLanguage();
  const isTelegram = !!profile.telegram;
  const notifications = profile.accountNotification;

  const handleTelegramConnect = () => {
    window.open(`${process.env.REACT_APP_TELEGRAM_URL}?start=${user.userId}_${telegramType}`, '_blank');
    setPopup(false);
    setPolling(true);
  };

  const handleTelegramDisconnect = () => {
    if (params) {
      dispatch(ThunkUser.updateNotification(params))
        .unwrap()
        .then(() => {
          notify(getNotifyContent(NotifyStatus.SUCCESS, UserActions.DisconnectTelegram, t));
          dispatch(updateTelegram(null));
          setPopup(false);
          setParams(null);
        });
    }
  };

  const handleChangeTelegram = (id: string, type: string, checked: boolean) => {
    const telegramOptions = notifications.filter(isTelegramNotification);
    if (telegramOptions.length === 2 || (telegramOptions.length !== 0 && checked)) {
      dispatch(ThunkUser.updateNotification({ id, type, enabled: checked }));
    } else {
      setParams({ id, type, enabled: checked });
      setTelegramType(type === ClientNotificationType.TelegramAppointment ? 'appointment' : 'marketing');
      setPopup(true);
    }
  };

  const handleChange = async (id: string, type: string, checked: boolean) => {
    if (isTelegramNotification(type)) {
      handleChangeTelegram(id, type, checked);
    } else {
      dispatch(ThunkUser.updateNotification({ id, type, enabled: checked }));
    }
  };

  useEffect(() => {
    state?.isScroll && ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <>
      <Flex pt="80px" mt="-80px" ref={ref} flexDirection="column" mb={['32px', '32px', '32px', '60px']}>
        <H3 mb={['8px', '8px', '8px', '60px']}>{t('profile.notifications')}</H3>
        {types.map(type => (
          <NotificationItem
            key={type}
            type={type}
            id={data[type].id}
            title={getTranslation(data[type].title, language)}
            info={getTranslation(data[type].descr, language)}
            disabled={isPolling && isTelegramNotification(type)}
            checked={notifications.includes(type)}
            onChange={handleChange}
            isLarge={isDesktop}
          />
        ))}
      </Flex>
      {isPopup && (
        <TelegramPopup
          isTelegram={isTelegram}
          setPopup={setPopup}
          connect={handleTelegramConnect}
          disconnect={handleTelegramDisconnect}
        />
      )}
    </>
  );
};

Notifications.displayName = 'Notifications';
