import { mediaQueries } from '@beauty/beauty-market-ui/dist/cjs';
import styled from 'styled-components';
import { Flex, Promo } from '@beauty/beauty-market-ui';

export const NoFavouritesWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 233px;
  height: calc(100vh - 276px);
  margin: auto;
  align-items: center;
  * {
    text-align: center !important;
  }
`;

export const PromoStyled = styled(Promo)`
  width: 334px !important;
`;

export const BreadcrumbsWrapper = styled(Flex)`
  margin-bottom: 37px;

  @media screen and (min-width: 768px) {
    margin-bottom: 46px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 69px;
  }
`;

export const SliderWrapper = styled(Flex)`
  align-self: flex-start;
  justify-self: start;
  width: calc(100vw - 16px);

  ${mediaQueries.md} {
    max-width: 1060px;
  }
`;

export const FavouritesListWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const HeadWrapper = styled(Flex)<{ empty: boolean }>`
  flex-direction: column;
  justify-content: end;

  height: ${({ empty }) => (empty ? 'auto' : '160px')};
  top: ${({ empty }) => (empty ? '104px' : '72px')};
  position: fixed;

  ${mediaQueries.md} {
    height: auto;
    position: sticky;
    top: 80px;
  }

  background-color: white;
  z-index: 1;
`;
