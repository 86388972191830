import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Footer } from '../../../components';
import { Search } from '../../../components/Search';
import { ServiceIconsBlock } from '../../../components/Services/components/ServiceIconsBlock';
import { useMediaScreen } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updateShowMenu } from '../../../store/redux-slices/headerSlice';
import { selectHistory, setFrom, setTopCategory } from '../../../store/redux-slices/historySlice';
import { AllOffersWrapper } from '../style';
import { FilterSidebar } from './FilterSidebar';
import CustomMap from './GoogleMap/CustomMap';
import { OffersList } from './OffersList';

export const AllOffers = () => {
  const { isMobile, isDesktop } = useMediaScreen('md');

  const { topCategory } = useAppSelector(selectHistory);

  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [offersView, setOffersView] = useState(state?.offersView || 'list');

  const setSelected = (name: string) => dispatch(setTopCategory(name));

  const handleOpenSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  const handleChangeView = () => {
    setOffersView(offersView === 'list' ? 'map' : 'list');
  };

  useEffect(() => {
    dispatch(setFrom('AllOffers'));
  }, []);

  useEffect(() => {
    dispatch(updateShowMenu({ isShowMenu: offersView === 'list' || (offersView === 'map' && isDesktop) }));
  }, [offersView, isDesktop]);

  return (
    <>
      <AllOffersWrapper isWide={offersView === 'map'}>
        {isMobile && <Search />}
        <ServiceIconsBlock
          selected={topCategory}
          onClick={setSelected}
          fromTop={offersView === 'map'}
          isBackground={isMobile && offersView === 'map'}
        />
        {offersView === 'list' ? <OffersList onFilterOpen={handleOpenSidebar} onChangeView={handleChangeView} /> : null}
        {offersView === 'map' ? <CustomMap onFilterOpen={handleOpenSidebar} onChangeView={handleChangeView} /> : null}
        {/* <FilterSidebar isOpen={sidebarIsOpen} onOpen={handleOpenSidebar} /> */}
      </AllOffersWrapper>
      {offersView === 'list' && <Footer />}
    </>
  );
};
