import { Flex, Avatar, H5, BodySmall, colors } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../hooks';

type RatingCardProps = {
  avatarUrl: string;
  title: string;
  info: string;
  isChecked?: boolean;
};

export const RatingCard = ({ avatarUrl, title, info, isChecked = false }: RatingCardProps) => {
  const { isMobile } = useMediaScreen('md');

  const avatarSize = (isChecked && 's') || (isMobile && 'l') || 'xl';

  return (
    <Flex flexDirection="column" alignItems="center">
      <Avatar size={avatarSize} url={avatarUrl} />
      <Flex flexDirection="column" justifyContent="end" mt="16px" mb={isChecked ? '40px' : '104px'} maxWidth="240px">
        <H5 mb="5px">{title}</H5>
        <BodySmall lowline color={colors.grey.dark}>
          {info}
        </BodySmall>
      </Flex>
    </Flex>
  );
};
