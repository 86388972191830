import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@beauty/beauty-market-ui';
import { useRequest } from '../../hooks';
import { useMediaScreen } from '../../hooks/useMediaScreen';
import { useAppSelector } from '../../store/hooks';
import { selectFavouriteId, ThunkUser } from '../../store/redux-slices/userSlice';
import { UserActions } from '../../types';
import { PopupSheet } from '../PopupSheet';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const FavouritePopup = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const selectedId = useAppSelector(selectFavouriteId);

  const removeFromFavourites = useRequest(ThunkUser.removeFromFavourites, UserActions.RemoveFromFavourites);

  const onDeleteClick = () => {
    removeFromFavourites({ orgId: selectedId });
    onClose();
  };

  const FooterBody = (
    <>
      <Button
        design="secondary"
        size={isDesktop ? 'large' : 'medium'}
        width="100%"
        onClick={onClose}
        mb={isDesktop ? '' : '8px'}
      >
        {t('favourites.modals.cancel')}
      </Button>
      <Button size={isDesktop ? 'large' : 'medium'} width="100%" onClick={onDeleteClick}>
        {t('favourites.modals.delete')}
      </Button>
    </>
  );

  return createPortal(
    <PopupSheet
      open={open}
      onClose={onClose}
      label={t('favourites.modals.organisation.title')}
      descriptor={t('favourites.modals.organisation.body')}
      FooterBody={FooterBody}
    />,
    document.getElementById('root') as HTMLElement,
  );
};
