import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H5, Div } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../../components/Organisation/OrganisationCard/OrganisationCard';
import { FilterType, OurChoiceOrganisations, getSelectedLanguage } from '../../../../constants';
import { getFullOrganisationLink, getTranslatedString, getTranslation, sortByOurChoice } from '../../../../helpers';
import { useMediaScreen } from '../../../../hooks';
import { useGetOrganisations } from '../../../../hooks/useGetOrganisations';
import { RouterUrl } from '../../../../routes/routes';
import { useAppSelector } from '../../../../store/hooks';
import { selectTopCategory } from '../../../../store/redux-slices/historySlice';
import { searchState } from '../../../../store/redux-slices/searchSlice';
import { selectUser } from '../../../../store/redux-slices/userSlice';
import { MarkerType } from '../../../../types';
import { FilterResultsWrapper, FilterResultsContent } from './style';

type FilterResultsSidebarProps = {
  sidebarLabel: string;
  hoveredItemId?: string;
  onHover?: (organisation: MarkerType) => void;
  onLeave?: () => void;
  setShareOrganisationId?: (orgId: string) => void;
};

const FilterResultsSidebar = ({ sidebarLabel, hoveredItemId, onHover, onLeave }: FilterResultsSidebarProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const topCategory = useAppSelector(selectTopCategory);
  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);
  const { favourites } = useAppSelector(selectUser);

  const { organisations: categoryOrganisations } = useGetOrganisations(topCategory);

  const language = getSelectedLanguage();
  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;

  const organisationsResults = useMemo(
    () =>
      sortByOurChoice(organisations).map(organisation => (
        <Div
          key={organisation.id}
          onMouseEnter={() =>
            onHover &&
            onHover({
              ...organisation,
              image: organisation.mainPhoto || '',
              headline: organisation.category.map(cat => getTranslation(cat, language)).join(', '),
              title: organisation.name,
            })
          }
          onMouseLeave={onLeave}
        >
          <OrganisationCard
            id={organisation.id}
            key={organisation.id}
            rating={organisation.rating}
            image={organisation.mainPhoto}
            headline={getTranslatedString(language, organisation.category)}
            title={organisation.name}
            photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
            reviews={t('organisation.reviews', { count: organisation.review })}
            badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
            href={getFullOrganisationLink(
              organisation.id,
              `?org=${organisation.name}&topCategory=${topCategory}`,
              organisation.id !== '0',
            )}
            isBlueHover
            favourite={favourites.ids?.includes(organisation.id)}
            state={{ redirectedFrom: RouterUrl.AllOffers, offersView: 'map' }}
          />
        </Div>
      )),
    [organisations, t, hoveredItemId, OurChoiceOrganisations, favourites.ids],
  );

  const filterResults = [...organisationsResults];

  const content = (
    <>
      <H5>{sidebarLabel}</H5>
      <FilterResultsContent>{filterResults}</FilterResultsContent>
    </>
  );

  return isDesktop ? <FilterResultsWrapper>{content}</FilterResultsWrapper> : null;
};

export default FilterResultsSidebar;
