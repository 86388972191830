import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H3, Button, SlidersIcon, PinIcon } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../components';
import { FilterType, OurChoiceOrganisations, getSelectedLanguage } from '../../../constants';
import { getFullOrganisationLink, getTranslatedString, sortByOurChoice } from '../../../helpers/utils';
import { AsyncComponent } from '../../../hoc/AsyncComponent';
import { useGetOrganisations } from '../../../hooks/useGetOrganisations';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectTopCategory } from '../../../store/redux-slices/historySlice';
import { selectAppliedFiltersCount, selectOffersFilter } from '../../../store/redux-slices/offersFilterSlice';
import { searchState } from '../../../store/redux-slices/searchSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { AllOffersHeader, AllOffersButtons, OffersGrid, AllOffersContainer } from '../style';

interface OffersListProps {
  onFilterOpen: () => void;
  onChangeView: (value: string) => void;
}

export const OffersList = ({ onFilterOpen, onChangeView }: OffersListProps) => {
  const { t } = useTranslation();

  const { isForceFetch, favourites } = useAppSelector(selectUser);
  const topCategory = useAppSelector(selectTopCategory);
  const { sorting } = useAppSelector(selectOffersFilter);
  const selectedFiltersCount = useAppSelector(selectAppliedFiltersCount);
  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);

  const { isLoading, organisations: categoryOrganisations } = useGetOrganisations(topCategory, isForceFetch);

  const language = getSelectedLanguage();
  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;

  const organizationsList = useMemo(() => sortByOurChoice(organisations), [organisations]);

  const buttonProps = {
    design: 'quaternary',
    size: 'small',
    width: '100%',
  };

  return (
    <AllOffersContainer>
      <AllOffersHeader>
        <H3>{`${t('home.chooseFrom')} ${organizationsList?.length} ${t('home.offerings')}`}</H3>
        <AllOffersButtons>
          {/* <Button
              {...buttonProps}
              onClick={onFilterOpen}
              prefix={<SlidersIcon height="15" width="15" />}
              count={selectedFiltersCount || false}
            >
              {t('home.filters')}
            </Button> */}
          <Button {...buttonProps} onClick={() => onChangeView('map')} prefix={<PinIcon height="15" width="15" />}>
            {t('home.showMap')}
          </Button>
        </AllOffersButtons>
      </AllOffersHeader>
      <AsyncComponent isLoading={isLoading}>
        <OffersGrid>
          {organizationsList.map(organisation => (
            <OrganisationCard
              id={organisation.id}
              key={organisation.id}
              rating={organisation.rating}
              image={organisation.mainPhoto}
              headline={getTranslatedString(language, organisation.category)}
              title={organisation.name}
              photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
              reviews={t('organisation.reviews', { count: organisation.review })}
              badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
              href={getFullOrganisationLink(
                organisation.id,
                `?org=${organisation.name}&topCategory=${topCategory}`,
                organisation.id !== '0',
              )}
              isBlueHover
              favourite={favourites.ids?.includes(organisation.id)}
              state={{ redirectedFrom: RouterUrl.AllOffers, offersView: 'list' }}
            />
          ))}
        </OffersGrid>
      </AsyncComponent>
    </AllOffersContainer>
  );
};
