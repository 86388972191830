import { NotifyContainer } from '@beauty/beauty-market-ui';
import { isRtl } from '../../helpers/rtl';
import { useMediaScreen } from '../../hooks';
import { Wrapper } from './style';

export const AlertContainer = () => {
  const { isMobile } = useMediaScreen('md');
  const rtl = isRtl();
  const defaultAlertPosition = rtl ? 'top-left' : 'top-right';
  return (
    <Wrapper>
      <NotifyContainer position={isMobile ? 'top-center' : defaultAlertPosition} rtl={rtl} />
    </Wrapper>
  );
};
