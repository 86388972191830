import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@beauty/beauty-market-ui';
import { Loader } from '..';

interface Props {
  disabled?: boolean;
  onBack?: () => void;
  onSubmit?: () => void;
  handleExtraAction?: (() => void) | null;
  extraActionLabel?: string;
  confirmationLabel?: string;
  cancelLabel?: string;
  save?: boolean;
  cancel?: boolean;
  info?: boolean;
  applyChanges?: boolean;
  openDetails?: boolean;
  isLoading?: boolean;
}

export const SidebarFooter = ({
  disabled = false,
  onSubmit,
  onBack,
  handleExtraAction = null,
  extraActionLabel = '',
  confirmationLabel = '',
  cancelLabel = '',
  save = false,
  cancel = false,
  info = false,
  applyChanges = false,
  openDetails = false,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation();
  const getSubmitLabel = () => {
    switch (true) {
      case !!confirmationLabel.length:
        return confirmationLabel;
      case save:
        return t('button.save');
      case applyChanges:
        return t('button.applyChanges');
      case openDetails:
        return t('button.openDetails');
      default:
        return t('button.next');
    }
  };

  const getCancelLabel = () => {
    switch (true) {
      case !!cancelLabel.length:
        return cancelLabel;
      case cancel:
        return t('button.cancel');
      default:
        return t('button.back');
    }
  };

  return (
    <Flex flexDirection="column" width="100%" gap="8px">
      {handleExtraAction ? (
        <Button design="quaternary" onClick={handleExtraAction} type="button" disabled={isLoading}>
          {extraActionLabel}
        </Button>
      ) : null}
      {!info && (
        <Button disabled={disabled || isLoading} onClick={onSubmit} type="submit">
          {isLoading ? <Loader /> : getSubmitLabel()}
        </Button>
      )}
      <Button design="secondary" onClick={onBack} type="button" disabled={isLoading}>
        {getCancelLabel()}
      </Button>
    </Flex>
  );
};
