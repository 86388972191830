import { useEffect, useMemo, useState } from 'react';
import { allCategoryText, getSelectedLanguage } from '../constants';
import { getTranslation } from '../helpers';
import { FavouritesSlideType } from '../page/Client/Favourites/types';
import { useAppSelector } from '../store/hooks';
import { selectUser } from '../store/redux-slices/userSlice';
import { TextWithTranslations } from '../types';
import { OrganisationRenderType } from '../types/organisation';

export const useGetFavouritesData = () => {
  const { isLoading, favourites: initialFavourites } = useAppSelector(selectUser);

  const [favourites] = useState(initialFavourites);
  const [activeCategory, setActiveCategory] = useState<string>('All');

  const language = getSelectedLanguage();

  const favouritesCategories: FavouritesSlideType[] = useMemo(() => {
    const favouritesCount = favourites.ids.length;
    const combinedCategories = favourites.items
      ? (Object.values(favourites.items).flatMap(org => org.category) as NonNullable<TextWithTranslations>[])
      : [];

    const categoriesWithCount: { [id: string]: number } = {};

    combinedCategories.forEach(cat => {
      if (cat.id in categoriesWithCount) categoriesWithCount[cat.id] += 1;
      else categoriesWithCount[cat.id] = 1;
    });

    const favouriteCategories: FavouritesSlideType[] = [
      {
        id: favouritesCount.toString(),
        name: allCategoryText as NonNullable<TextWithTranslations>,
        count: favouritesCount,
      },
      ...Object.entries(categoriesWithCount).map(([id, count]) => ({
        id,
        name: combinedCategories.find(item => item.id === id) as NonNullable<TextWithTranslations>,
        count,
      })),
    ];

    return favouriteCategories.sort((a: FavouritesSlideType, b: FavouritesSlideType) => b.count - a.count);
  }, [favourites]);

  const categories = useMemo(
    () =>
      favouritesCategories.map(category => ({
        id: category.name.text,
        text: getTranslation(category.name, language),
        number: category.count,
        design: category.name.text === activeCategory ? 'active' : 'default',
      })),
    [favouritesCategories, language, activeCategory],
  );

  const displayedFavourites: OrganisationRenderType[] = useMemo(
    () =>
      activeCategory === 'All'
        ? Object.values(favourites.items)
        : Object.values(favourites.items).filter(item =>
            item.category.find(category => category?.text === activeCategory),
          ),
    [favourites, activeCategory],
  );

  useEffect(() => {
    !favouritesCategories.find(cat => cat.name.text === activeCategory) && setActiveCategory('All');
  }, [favouritesCategories, activeCategory]);

  return {
    isLoading,
    activeCategory,
    setActiveCategory,
    count: favourites.ids?.length || 0,
    favourites: displayedFavourites,
    categories,
  };
};
