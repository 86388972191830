import { profileAPI } from '../../helpers/profile';
import { OrganisationRenderType } from '../../types/organisation';
import { PatchNotificationResponse } from '../../types/user';
import { createAppAsyncThunk } from '../create-app-async-thunk';

export const updateNotification = createAppAsyncThunk<
  PatchNotificationResponse,
  { id: string; type: string; enabled: boolean }
>('user/updateNotification', (data, thunkAPI) => {
  const { getState, rejectWithValue } = thunkAPI;
  const params = {
    query: {
      profileId: getState().user.user.userId,
      notificationId: data.id,
    },
    data: { enabled: data.enabled },
    rejectWithValue,
  };
  return profileAPI.updateNotification(params);
});

export const disconnectTelegram = createAppAsyncThunk<void, void>(
  'user/disconnectTelegram',
  (_, { getState, rejectWithValue }) =>
    profileAPI.disconnectTelegram({ id: getState().user.user.userId, rejectWithValue }),
);

export const fetchFavourites = createAppAsyncThunk<OrganisationRenderType[], void>(
  'user/fetchFavourites',
  (_, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    return profileAPI.fetchFavourites({ id: getState().user.user.userId, rejectWithValue });
  },
);

export const addToFavourites = createAppAsyncThunk<OrganisationRenderType, { orgId: string }>(
  'user/addToFavourites',
  (data, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    return profileAPI.addToFavourites({ id: getState().user.user.userId, orgId: data.orgId, rejectWithValue });
  },
);

export const removeFromFavourites = createAppAsyncThunk<void, { orgId: string }>(
  'user/removeFromFavourites',
  (data, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    return profileAPI.removeFromFavourites({ id: getState().user.user.userId, orgId: data.orgId, rejectWithValue });
  },
);
