import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'tsdef';
import { clearBooking, EmptyFavourites, EmptyProfile } from '../../constants';
import { BookingType } from '../../types/booking';
import { RenderNotificationsType } from '../../types/notifications';
import { OrganizationsMap } from '../../types/organisation';
import { ProfileType, StoreFavouritesType, UserType } from '../../types/user';
import {
  addToFavourites,
  disconnectTelegram,
  fetchFavourites,
  removeFromFavourites,
  updateNotification,
} from '../asyncActions';
import { RootState } from '../store';

export interface UserState {
  isLoading: boolean;
  isLogin: boolean;
  user: UserType;
  booking: BookingType;
  profile: ProfileType;
  selectedAppointmentId?: string;
  smsCodeTime: number;
  notifications: RenderNotificationsType;
  activeNotificationId: Nullable<string>;
  isForceFetch: boolean;
  favourites: StoreFavouritesType;
}

const initialState: UserState = {
  isLoading: false,
  isLogin: false,
  user: { name: '', surname: '', email: '', code: '', number: '', userId: '', avatarUrl: '', idNumber: '' },
  booking: clearBooking,
  profile: EmptyProfile,
  selectedAppointmentId: undefined,
  smsCodeTime: 0,
  notifications: { new: [], archive: [] },
  activeNotificationId: null,
  isForceFetch: false,
  favourites: EmptyFavourites,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (
      state,
      action: PayloadAction<{ isLogin: boolean; user: UserType; selectedAppointmentId?: string }>,
    ) => {
      const { isLogin, user, selectedAppointmentId } = action.payload;
      state.isLogin = isLogin;
      state.user = user;
      state.selectedAppointmentId = selectedAppointmentId;
    },
    updateBooking: (state, action: PayloadAction<BookingType>) => {
      state.booking = { ...state.booking, ...action.payload };
    },
    updateProfile: (state, action: PayloadAction<ProfileType>) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    updateSmsCodeTime: (state, action: PayloadAction<number>) => {
      state.smsCodeTime = action.payload;
    },
    resetProfile: state => {
      state.profile = EmptyProfile;
      state.favourites = EmptyFavourites;
    },
    setNotifications: (state, action: PayloadAction<RenderNotificationsType>) => {
      state.notifications = action.payload;
    },
    updateNotificationStatus: (state, action: PayloadAction<{ isNew: boolean; index: number }>) => {
      const { isNew, index } = action.payload;
      if (state.notifications) state.notifications[isNew ? 'new' : 'archive'][index].isRead = true;
    },
    setActiveNotificationId: (state, action: PayloadAction<Nullable<string>>) => {
      state.activeNotificationId = action.payload;
    },
    setForceFetch: (state, action: PayloadAction<boolean>) => {
      state.isForceFetch = action.payload;
    },
    updateTelegram: (state, action: PayloadAction<null>) => {
      state.profile.telegram = action.payload;
    },
    setFavouriteId: (state, action: PayloadAction<string>) => {
      state.favourites.selectedId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateNotification.fulfilled, (state, action) => {
        const index = state.profile.accountNotification.findIndex(type => type === action.meta.arg.type);
        if (index !== -1) {
          state.profile.accountNotification.splice(index, 1);
        } else {
          state.profile.accountNotification.push(action.payload.type);
        }
      })
      .addCase(disconnectTelegram.fulfilled, state => {
        state.profile.telegram = null;
      })
      .addCase(fetchFavourites.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchFavourites.fulfilled, (state, action) => {
        const items = action.payload;
        const itemsRecord = {} as OrganizationsMap;
        items.forEach(item => {
          itemsRecord[item.id] = item;
        });
        state.favourites = { ids: items.map(item => item.id), items: itemsRecord, selectedId: null };
        state.isLoading = false;
      })
      .addCase(addToFavourites.fulfilled, (state, action) => {
        state.favourites.ids && state.favourites.ids.push(action.payload.id);
        state.favourites.items[action.payload.id] = action.payload;
        state.favourites.selectedId = null;
        state.isLoading = false;
      })
      .addCase(removeFromFavourites.fulfilled, state => {
        if (state.favourites.ids)
          state.favourites.ids = state.favourites.ids.filter(id => id !== state.favourites.selectedId);
        delete state.favourites.items[state.favourites.selectedId || ''];
        state.favourites.selectedId = null;
        state.isLoading = false;
      });
  },
});

export const {
  updateUser,
  updateBooking,
  updateProfile,
  updateSmsCodeTime,
  resetProfile,
  setNotifications,
  updateNotificationStatus,
  setActiveNotificationId,
  setForceFetch,
  updateTelegram,
  setFavouriteId,
} = userSlice.actions;

export const ThunkUser = {
  updateNotification,
  fetchFavourites,
  addToFavourites,
  removeFromFavourites,
};

export const selectUser = (state: RootState) => state.user;
export const selectUserId = (state: RootState) => state.user.user.userId;
export const selectFavouritesIds = (state: RootState) => state.user.favourites.ids;
export const selectFavouriteId = (state: RootState) => state.user.favourites.selectedId;

export default userSlice.reducer;
