import { Flex, H4, BodySmall, colors } from '@beauty/beauty-market-ui';
import InfoIcon from '../assets/info-image.png';

type NoDataProps = {
  title: string;
  info: string;
};

export const NoData = ({ title, info }: NoDataProps) => (
  <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%" mt="40px">
    <img src={InfoIcon} alt="no data" />
    <H4 width="260px" mt="24px" mb="8px" textAlign="center!important">
      {title}
    </H4>
    <BodySmall width="260px" pb={[0, 0, 0, '24px']} textAlign="center!important" color={colors.grey.dark}>
      {info}
    </BodySmall>
  </Flex>
);
