import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { H3 } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../components';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../constants';
import { getFullOrganisationLink, getTranslatedString } from '../../../helpers/utils';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectFavouritesIds } from '../../../store/redux-slices/userSlice';
import { TextWithTranslations } from '../../../types';
import { StyledSlider } from '../style';

type HotOfferType = {
  id: string;
  name: string;
  rating: string | null;
  review: number;
  photoCount: number;
  mainPhoto?: string | null;
  category?: TextWithTranslations[] | TextWithTranslations;
};

interface HotOffersProps {
  offers: HotOfferType[];
}

const HotOffers = ({ offers }: HotOffersProps) => {
  const { t } = useTranslation();

  const ids = useAppSelector(selectFavouritesIds);

  const location = useLocation();
  const language = getSelectedLanguage();

  const lastSlashIndex = location.pathname.lastIndexOf('/');
  const orgId = lastSlashIndex !== -1 ? location.pathname.substring(lastSlashIndex + 1) : '';

  const preparedQuery = (query: string) =>
    location.search.replace(/(\[?|&])org=[^&]*/, `$1org=${encodeURIComponent(query)}`);

  const slides = offers.map(offer => (
    <OrganisationCard
      id={offer.id}
      key={offer.id}
      title={offer.name}
      rating={offer.rating}
      image={offer.mainPhoto}
      headline={getTranslatedString(language, offer.category)}
      href={getFullOrganisationLink(offer.id, preparedQuery(offer.name))}
      reviews={t('organisation.reviews', { count: offer.review })}
      photos={t('organisation.photos', { count: offer.photoCount ? offer.photoCount - 1 : 0 })}
      badge={OurChoiceOrganisations.includes(offer.id) ? t('organisation.ourChoice') : ''}
      isBlueHover
      favourite={ids?.includes(offer.id)}
      state={{
        redirectedFrom: RouterUrl.Organisation,
        searchParams: window.location.search,
        orgId,
      }}
    />
  ));

  const title = <H3>{t('organisation.otherBranches')}</H3>;

  return <StyledSlider title={title} slides={slides} width="100%" />;
};

export default HotOffers;
