import { useTranslation } from 'react-i18next';
import { OrganisationCard } from '../../../../components';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../../constants';
import { getFullOrganisationLink, getTranslatedString } from '../../../../helpers';
import { useAppSelector } from '../../../../store/hooks';
import { selectFavouritesIds } from '../../../../store/redux-slices/userSlice';
import { OffersGrid } from '../../../HomePage/style';
import { FavouritesOffer } from '../types';

type Props = {
  favourites: FavouritesOffer[];
};

export const FavouritesContent = ({ favourites }: Props) => {
  const { t } = useTranslation();

  const favouriteIds = useAppSelector(selectFavouritesIds);
  const language = getSelectedLanguage();

  return (
    <OffersGrid mt={['128px', '128px', '128px', '0']}>
      {favourites.map(organisation => (
        <OrganisationCard
          id={organisation.id}
          key={organisation.id}
          rating={organisation.rating}
          image={organisation.mainPhoto}
          headline={getTranslatedString(language, organisation.category)}
          title={organisation.name}
          photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
          reviews={t('organisation.reviews', { count: organisation.review })}
          badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
          href={getFullOrganisationLink(organisation.id)}
          isBlueHover
          favourite={favouriteIds?.includes(organisation.id)}
        />
      ))}
    </OffersGrid>
  );
};
