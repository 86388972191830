import { ReactNode } from 'react';
import { BottomSheet, Popup } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../hooks';

type Props = {
  label: string;
  descriptor: string | ReactNode;
  FooterBody: ReactNode;
  onClose: () => void;
  open: boolean;
};

export const PopupSheet = ({ open, onClose, label, descriptor, FooterBody }: Props) => {
  const { isDesktop } = useMediaScreen('md');

  return isDesktop ? (
    <Popup open={open} title={label} info={descriptor} FooterBody={FooterBody} handleClose={onClose} />
  ) : (
    <BottomSheet
      isOpen={open}
      label={label}
      descriptor={descriptor}
      content={FooterBody}
      handleClose={onClose}
      detent="content-height"
    />
  );
};
