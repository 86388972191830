import { NavigateFunction } from 'react-router-dom';
import _ from 'lodash';
import { RouterUrl } from '../routes/routes';
import { RedirectState } from '../types';

const getRedirectUrl = (state: RedirectState) => {
  const isRedirectedFromOrganisation =
    state.redirectedFrom === RouterUrl.Booking || state.redirectedFrom === RouterUrl.Organisation;
  const isRedirectedFromProfile = state.redirectedFrom === RouterUrl.ClientProfile;

  if (isRedirectedFromOrganisation) return `${state.redirectedFrom}/${state.orgId}/${state.searchParams}`;
  if (isRedirectedFromProfile) return `${RouterUrl.ClientProfile}/${state.id}`;
  return state.redirectedFrom || RouterUrl.Homepage;
};

const getRedirectData = (state: RedirectState) => {
  const hasView = _.has(state, 'offersView');
  const isRedirectedFromBooking = state.redirectedFrom === RouterUrl.Booking;
  const isRedirectedFromProfile = state.redirectedFrom === RouterUrl.ClientProfile;

  const otherData = hasView ? { offersView: state?.offersView } : { isScroll: isRedirectedFromProfile };

  return {
    state: isRedirectedFromBooking
      ? {
          shouldOpenSidebar: true,
        }
      : otherData,
  };
};

export const redirectAfterLogin = (navigate: NavigateFunction, state: RedirectState) => {
  navigate(getRedirectUrl(state), getRedirectData(state));
};
