import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  H4,
  H5,
  Flex,
  colors,
  Sidebar,
  Button,
  Separator,
  ShowMore,
  ShareButton,
  FavouriteButton,
  Slider,
  DynamicComponent,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers/utils';
import { useGetServiceInfo } from '../../../hooks/useGetServiceInfo';
import { useToggle } from '../../../hooks/useToggle';
import { useAppSelector } from '../../../store/hooks';
import { selectOrganisation } from '../../../store/redux-slices/organisationSlice';
import { ServiceType, TextWithTranslations } from '../../../types';
import { ShareOrganisationModal } from '../../Organisation/ShareOrganisation/ShareOrganisationModal';
import { ControlButtons, PriceBlock, OldPrice, SliderWrapper } from './style';

interface ServicesSidebarProps {
  setIsOpen: (open: boolean) => void;
  organisationName: string;
  serviceId: string;
  onSubmitButton: (service?: ServiceType | null) => void;
  isServiceSelected?: boolean;
}

const getSlides = (photos: string[]) => photos.map(photo => <DynamicComponent as="img" src={photo} key={photo} />);

const ServicesSidebar = ({
  setIsOpen,
  organisationName,
  serviceId,
  onSubmitButton,
  isServiceSelected = false,
}: ServicesSidebarProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const [isOpenModal, setIsOpenModal] = useToggle();
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  const [isShareModalOpen, setShareModalOpen] = useToggle();
  const { orgId } = useParams();

  const { serviceInfo: service } = useGetServiceInfo(serviceId);
  const { selectedService } = useAppSelector(selectOrganisation);

  const handleSubmit = () => {
    onSubmitButton(selectedService);
    setIsOpen(false);
  };

  const serviceSeparator = <Separator mt="24px" mb="32px" />;

  const handleModalOpen = () => {
    setShareModalOpen();
  };

  const overviewSection = (
    <>
      <Flex mt="24px" justifyContent="space-between">
        <Flex flexDirection="column">
          <H4 mb="16px">{service?.title ? getTranslation(service.title as TextWithTranslations, language) : ''}</H4>
          <PriceBlock>
            <H4 color={colors.blue.standard}>{service?.price}</H4>
            <OldPrice lowline color={colors.grey.dark}>
              {service?.oldPrice}
            </OldPrice>
          </PriceBlock>
        </Flex>
        <ControlButtons>
          {/* <FavouriteButton onClick={setIsOpenModal} /> */}
          <ShareButton onShare={handleModalOpen} />
        </ControlButtons>
      </Flex>
      {serviceSeparator}
    </>
  );

  const serviceAboutSection = (
    <>
      <H5 mb="24px">{t('organisation.booking.serviceAbout')}</H5>
      <ShowMore
        text={service?.description ? getTranslation(service?.description as TextWithTranslations, language) : ''}
        visibleLines={6}
        showMore={t('organisation.about.showMore')}
        showLess={t('organisation.about.showLess')}
      />
      {serviceSeparator}
    </>
  );

  const sidebarConfirmationButton = (
    <Button width="100%" disabled={isServiceSelected} onClick={handleSubmit}>
      {t(`organisation.booking.${isServiceSelected ? 'serviceAlreadySelected' : 'selectService'}`)}
    </Button>
  );

  const slides = getSlides(service?.photos || []);

  const content = (
    <>
      <SliderWrapper height="244px">
        <Slider slides={slides} isSingleSlide isPaginationEnabled />
      </SliderWrapper>
      {overviewSection}
      {serviceAboutSection}
      {/* <FavouritePopup isOpen={isOpenModal} handlePopupClose={setIsOpenModal} /> */}
      {isShareModalOpen && (
        <ShareOrganisationModal open={isShareModalOpen} onClose={setShareModalOpen} organisationId={orgId || ''} />
      )}
    </>
  );

  const closeSidebar = () => setIsOpen(false);

  const generalProps = {
    isOpen: true,
    onClose: closeSidebar,
    handleClose: closeSidebar,
    label: t('organisation.about.services'),
    descriptor: organisationName,
    FooterBody: sidebarConfirmationButton,
  };

  return isLarge ? (
    <Sidebar {...generalProps}>{content}</Sidebar>
  ) : (
    <BottomSheet {...generalProps} content={content} detent="content-height" />
  );
};

export default ServicesSidebar;
