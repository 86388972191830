import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import moment from 'moment';
import { Button, CoverStack, CoverStackSpecialist, H3, NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { SidebarSheet } from '../../../components';
import { createAppointment, updateAppointment } from '../../../helpers/appointments';
import { getAppointmentNotifyContent } from '../../../helpers/notifyContent/appointment';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { selectAppointments, setLastAddedId } from '../../../store/redux-slices/appointmentsSlice';
import { selectUser, updateBooking, updateUser } from '../../../store/redux-slices/userSlice';
import { Spinner } from '../../../style';
import { AppointmentActions, PatchAppointmentType } from '../../../types/appointment';
import { EventStatus } from '../../Client/Appointments/constants';
import { FullStatus } from '../../Client/Appointments/types';
import { getAppointmentParams, handleError, PayBy } from '../helpers';
import { StatePanelInfo, Wrapper } from '../style';
import AccountLogIn from './AccountLogIn';
import PaymentMethods from './PaymentMethods';
import PersonalData from './PersonalData/PersonalData';
import { PersonalDataFormTypes } from './PersonalData/PersonalData.definitions';
import SelectedService from './SelectedService';
import SelectedSpecialist from './SelectedSpecialist';
import SelectedTime from './SelectedTime';

interface ServicesSidebarProps {
  setIsOpen: (open: boolean) => void;
  organisation: any;
}

const CheckoutSidebar = ({ setIsOpen, organisation }: ServicesSidebarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotify();

  const formRef = useRef<FormikProps<PersonalDataFormTypes>>();

  const [paymentMethod, setPaymentMethod] = useState<string>(PayBy.Cash);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  const user = useAppSelector(selectUser);
  const { booking } = user;
  const { appointments } = useAppSelector(selectAppointments);
  const isBookDisabled = !isValid || !paymentMethod;

  const handleSuccess = (id: string, action: AppointmentActions) => {
    dispatch(updateBooking({ timeslot: null }));
    dispatch(setLastAddedId(id));
    notify(getAppointmentNotifyContent(NotifyStatus.SUCCESS, action, t));
    if (user.isLogin) {
      navigate(`${RouterUrl.ClientAppointments}/${user.user.userId}`);
    } else if (organisation.id) {
      navigate(`${RouterUrl.Organisation}/${organisation.id}${location.search}`);
    }
    setIsOpen(false);
  };

  const handleSubmit = () => formRef.current && formRef.current.submitForm();

  const onFormSubmit = useCallback(
    async (clientData: PersonalDataFormTypes) => {
      setLoading(true);
      const selectedAppointment = appointments?.find(app => app.id === user.selectedAppointmentId);
      const createAction = user.isLogin ? AppointmentActions.Create : AppointmentActions.CreateByGuest;
      const actionType =
        selectedAppointment?.status && [EventStatus.CONFIRMED, EventStatus.PENDING].includes(selectedAppointment.status)
          ? AppointmentActions.Update
          : createAction;

      if (actionType === AppointmentActions.Update && user.selectedAppointmentId) {
        const start = moment(booking.timeslot!.id.replace(':', ' '));
        const updateAppointmentParams: PatchAppointmentType = {
          orgSpecId: booking.specialist?.id as string,
          orgServId: booking.service!.id,
          start: start.parseZone().toISOString(),
          end: start
            .add(booking.service?.duration, 'm')
            .parseZone()
            .toISOString(),
          status: FullStatus.PENDING,
        };

        const updateResponse = await updateAppointment(user.selectedAppointmentId, updateAppointmentParams);
        updateResponse?.status === 200
          ? handleSuccess(updateResponse.data.id, actionType)
          : handleError(actionType, notify, t);
      } else {
        const data = getAppointmentParams(booking, paymentMethod, user.user.userId, clientData, user.isLogin, false);
        const { response, error } = await createAppointment(data);
        response?.success ? handleSuccess(response.data.id, actionType) : handleError(actionType, notify, t, error);
      }

      dispatch(updateUser({ ...user, selectedAppointmentId: undefined }));
      setLoading(false);
    },
    [user, paymentMethod, user.isLogin, user.user.userId, notify],
  );

  const handleSignInClick = () => {
    navigate(RouterUrl.Login, {
      state: { redirectedFrom: RouterUrl.Booking, searchParams: window.location.search, orgId: organisation.id },
    });
  };

  const coverStackProps = {
    specialistType: t(organisation.offers.categories.map(cat => cat.name).join(', ')),
    specialistLabel: organisation.name,
    // rating: organisation.rating.total,
    imgUrl: organisation.mainPhoto,
  };

  const coverStackContent = (
    <StatePanelInfo>
      <SelectedService />
      <SelectedTime />
      <SelectedSpecialist />
    </StatePanelInfo>
  );

  const content = (
    <Wrapper isLogin={user.isLogin}>
      <CoverStack header={<CoverStackSpecialist {...coverStackProps} />} width="100%" content={coverStackContent} />
      <H3 mt="40px">{t('organisation.booking.dataPayment')}</H3>
      {!user.isLogin && <AccountLogIn onSignInClick={handleSignInClick} />}
      <PersonalData ref={formRef} onFormSubmit={onFormSubmit} setIsValid={setIsValid} />
      <PaymentMethods method={paymentMethod} setMethod={setPaymentMethod} />
    </Wrapper>
  );

  const footer = (
    <Button
      type="submit"
      disabled={isBookDisabled || isLoading}
      design="primary"
      width="100%"
      size="large"
      onClick={handleSubmit}
    >
      {isLoading ? <Spinner /> : t('organisation.booking.book')}
    </Button>
  );

  return (
    <SidebarSheet
      isOpen
      onClose={() => setIsOpen(false)}
      label={t('organisation.booking.checkout')}
      descriptor={organisation.name}
      FooterBody={footer}
    >
      {content}
    </SidebarSheet>
  );
};

export default CheckoutSidebar;
