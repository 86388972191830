import { useTranslation } from 'react-i18next';
import { H2, Slider, TagButton } from '@beauty/beauty-market-ui';
import { Container, Crumbs } from '../../../components';
import { isRtl } from '../../../helpers';
import { AsyncComponent } from '../../../hoc';
import { useGetFavouritesData } from '../../../hooks';
import { FavouritesContent } from './components/FavouritesContent';
import NoFavourites from './components/NoFavourites';
import { HeadWrapper, SliderWrapper } from './style';

const Favourites = () => {
  const { t } = useTranslation();

  const { isLoading, count, favourites, categories, setActiveCategory } = useGetFavouritesData();
  const rtl = isRtl();

  const slides = categories.map(category => (
    <TagButton
      onClick={() => setActiveCategory(category.id)}
      text={category.text}
      number={category.number}
      key={category.id}
      design={category.design}
    />
  ));

  return (
    <AsyncComponent isLoading={isLoading}>
      <Container flexDirection="column" mt={['24px', '24px', '24px', 0]}>
        <HeadWrapper empty={!count}>
          <Crumbs />
          <H2>{t('favourites.title')}</H2>
          {!!count && (
            <SliderWrapper height={['64px', '64px', '64px', '72px']} mt={['32px', '32px', '32px', '40px']}>
              <Slider inlineSlider slides={slides} width="100%" rtl={rtl} />
            </SliderWrapper>
          )}
        </HeadWrapper>
        {count ? <FavouritesContent favourites={favourites} /> : <NoFavourites />}
      </Container>
    </AsyncComponent>
  );
};

export default Favourites;
