import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageCropper, Caption, ArrowLeftIcon } from '@beauty/beauty-market-ui';
import { SidebarSheet } from '../../../../../components';
import { SidebarFooter } from '../../../../../components/SidebarFooter';
import { isRtl } from '../../../../../helpers';
import { useMediaScreen } from '../../../../../hooks';
import { BackWrapper } from '../../style';

export type UploadPhotoSidebarProps = {
  onClose: () => void;
  onSubmit: (photo: File) => void;
  onChangeMode: () => void;
};

export const UploadPhotoSidebar = ({ onClose, onSubmit, onChangeMode }: UploadPhotoSidebarProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const [isCrop, setIsCrop] = useState(false);
  const [isLastStep, setLastStep] = useState(false);

  const rtl = isRtl();

  const savePhoto = () => {
    setIsCrop(true);
    setLastStep(false);
    setTimeout(onClose, 200);
  };

  const handleClose = () => {
    onChangeMode();
    setLastStep(false);
    onClose();
  };

  const footerBody = (
    <SidebarFooter
      disabled={!isLastStep}
      onSubmit={savePhoto}
      onBack={handleClose}
      confirmationLabel={t('button.save')}
      cancelLabel={t('button.cancel')}
    />
  );

  const handleBackClick = useCallback(() => {
    if (isLastStep) {
      setLastStep(false);
    } else {
      handleClose();
    }
  }, [isLastStep, handleClose]);

  const sidebarDescriptor = isDesktop ? (
    <BackWrapper onClick={handleBackClick}>
      <ArrowLeftIcon width="16px" height="16px" />
      <Caption large>{t('button.back')}</Caption>
    </BackWrapper>
  ) : null;

  const generalProps = {
    isOpen: true,
    label: `${t(`uploadPhoto.new`)} ${t('uploadPhoto.photo')}`,
    descriptor: sidebarDescriptor,
    onClose: handleClose,
    onBack: isDesktop ? undefined : handleClose,
    FooterBody: footerBody,
  };

  const content = (
    <ImageCropper
      dragText={t('uploadPhoto.dragText')}
      browseText={t('uploadPhoto.browseText')}
      restrictionsText={t('uploadPhoto.restrictionsText')}
      errorText={t('uploadPhoto.errorText')}
      maxZoom={5}
      isCrop={isCrop}
      setIsCrop={setIsCrop}
      onCrop={onSubmit}
      isLastStep={isLastStep}
      setLastStep={setLastStep}
      rtl={rtl}
    />
  );

  return <SidebarSheet {...generalProps}>{content}</SidebarSheet>;
};
