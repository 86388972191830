import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Nullable } from 'tsdef';
import { Sidebar, BottomSheet } from '@beauty/beauty-market-ui';
import { SidebarFooter } from '../../../../../components';
import { useMediaScreen } from '../../../../../hooks';
import { ProfileType } from '../../../../../types/user';
import { ProfileForm } from '../../Profile.definitions';
import { EditProfileForm } from '../Forms/EditProfile/EditProfileForm';
import { UploadPhotoSidebar } from './UploadPhotoSidebar';

export type EditProfileSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  profile: ProfileType;
  setPhoto: (image?: Nullable<File>) => void;
  isEditMode: boolean;
  setEditMode: (isEditMode: boolean) => void;
  photo?: Nullable<File>;
};

export const EditProfileSidebar = ({
  profile,
  isOpen,
  setIsOpen,
  setPhoto,
  photo,
  isEditMode,
  setEditMode,
}: EditProfileSidebarProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);

  const formRef = useRef<FormikProps<ProfileForm>>();

  const handleClose = () => {
    setEditMode(false);
    setIsOpen(false);
    setPhoto(undefined);
  };

  const content = (
    <EditProfileForm
      ref={formRef}
      profile={profile}
      photo={photo}
      setPhoto={setPhoto}
      onSubmit={handleClose}
      setSubmitButtonActive={setIsSubmitActive}
      setUploadOpen={setUploadOpen}
      isEditMode={isEditMode}
      setEditMode={setEditMode}
    />
  );

  const footerBody = (
    <SidebarFooter
      disabled={!isSubmitActive}
      onSubmit={() => {
        formRef.current && formRef.current.handleSubmit();
      }}
      onBack={handleClose}
      confirmationLabel={t('button.save')}
      cancelLabel={t('button.cancel')}
    />
  );

  const generalProps = {
    isOpen,
    label: t('profile.editProfile'),
    onClose: handleClose,
    handleClose,
    FooterBody: footerBody,
  };

  const uploadComponent = isUploadOpen && (
    <UploadPhotoSidebar
      onClose={() => setUploadOpen(false)}
      onSubmit={setPhoto}
      onChangeMode={() => setEditMode(false)}
    />
  );

  return (
    <>
      {isDesktop ? (
        <Sidebar {...generalProps}>{content}</Sidebar>
      ) : (
        <BottomSheet {...generalProps} detent="content-height" content={content} />
      )}
      {uploadComponent}
    </>
  );
};
