import { BodySmall, H6, Flex } from '@beauty/beauty-market-ui';
import { useAppSelector } from '../../../store/hooks';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { GreyBodySmall } from '../style';

const SelectedService = () => {
  const { booking } = useAppSelector(selectUser);

  return booking.service ? (
    <Flex justifyContent="space-between" alignItems="center" width="inherit">
      <Flex flexDirection="column" gap="2px">
        <BodySmall>{booking.service.label}</BodySmall>
        <Flex>
          <GreyBodySmall lowline>{booking.service.description}</GreyBodySmall>
          <GreyBodySmall lowline> • </GreyBodySmall>
          <GreyBodySmall lowline>{booking.service.title}</GreyBodySmall>
        </Flex>
      </Flex>
      <H6>{booking.service.price}</H6>
    </Flex>
  ) : null;
};

export default SelectedService;
