import { useTranslation } from 'react-i18next';
import { H3 } from '@beauty/beauty-market-ui';
import { Services, Timeslots } from '../../../components';
import { CategoryType, SpecialRequirementType } from '../../../types';
import { OrganisationWorkTime } from '../../Organisation/types';
import { SelectPanelWrapper } from '../style';

interface SelectPanelProps {
  showTimeslots: boolean;
  offers: {
    categories: CategoryType[];
    specialRequirement?: SpecialRequirementType;
  };
  orgTimezone: string;
  organisationName: string;
  workSchedule: OrganisationWorkTime[];
  selectedId?: string;
}

const SelectPanel = ({
  showTimeslots,
  offers,
  orgTimezone,
  organisationName,
  selectedId,
  workSchedule,
}: SelectPanelProps) => {
  const { t } = useTranslation();

  const servicesProps = {
    offersCategories: offers.categories,
    organisationName,
  };

  const timeslotsProps = {
    orgTimezone,
    selectedDay: selectedId?.substring(0, 10),
    workSchedule,
  };

  return (
    <SelectPanelWrapper>
      {!showTimeslots && <H3>{t('organisation.offerings')}</H3>}
      {showTimeslots ? <Timeslots {...timeslotsProps} /> : <Services {...servicesProps} />}
    </SelectPanelWrapper>
  );
};

export default SelectPanel;
