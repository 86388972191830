import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import { Flex, Slider, Promo } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../components';
import { StyledPromo } from '../../../components/Organisation/style';
import { ServiceIconsBlock } from '../../../components/Services/components/ServiceIconsBlock';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../constants';
import { isRtl } from '../../../helpers/rtl';
import { getFullOrganisationLink, getTranslatedString, sortByOurChoice } from '../../../helpers/utils';
import { AsyncComponent } from '../../../hoc';
import { useMediaScreen } from '../../../hooks';
import { useGetOrganisations } from '../../../hooks/useGetOrganisations';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHistory, setTopCategory } from '../../../store/redux-slices/historySlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { OrganisationRenderType } from '../../../types/organisation';
import { OffersGrid, StyledButton } from '../style';

export const OrganisationsSection = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('sm');

  const { isForceFetch, favourites } = useAppSelector(selectUser);
  const { topCategory } = useAppSelector(selectHistory);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, organisations } = useGetOrganisations(topCategory, isForceFetch);

  const rtl = isRtl();
  const language = getSelectedLanguage();

  const setSelectedTopCategory = (id: string) => dispatch(setTopCategory(id));

  const promoCard = (
    <StyledPromo>
      <Promo headline="" title={t('home.placeForBusiness')} isBlueHover />
    </StyledPromo>
  );

  const organizationsList: Nullable<ReactElement[]> = useMemo(() => {
    let list = organisations ? [...sortByOurChoice(organisations)] : [];
    isDesktop && (list = list.slice(0, 6));

    const result = !isLoading
      ? list.map((organisation: OrganisationRenderType) => (
          <OrganisationCard
            id={organisation.id}
            key={organisation.id}
            rating={organisation.rating}
            image={organisation.mainPhoto}
            headline={getTranslatedString(language, organisation.category)}
            title={organisation.name}
            photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
            reviews={t('organisation.reviews', { count: organisation.review })}
            badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
            href={getFullOrganisationLink(
              organisation.id,
              `?org=${organisation.name}&topCategory=${topCategory}&from=Home`,
            )}
            isBlueHover
            favourite={favourites.ids?.includes(organisation.id)}
            state={{}}
          />
        ))
      : null;

    result && result.length < 6 && result.push(promoCard);
    return result;
  }, [organisations, topCategory, isDesktop, t, isLoading, OurChoiceOrganisations, favourites.ids]);

  return (
    <Flex flexDirection="column" backgroundColor="grey" mb={['80px', '80px', '160px', '160px']}>
      <ServiceIconsBlock selected={topCategory} onClick={setSelectedTopCategory} />
      <AsyncComponent isLoading={isLoading}>
        <Flex mt={['32px', '32px', '32px', '0']} mb={['0', '0', '40px', '40px']}>
          {isDesktop ? (
            <OffersGrid>{organizationsList}</OffersGrid>
          ) : (
            !isEmpty(organizationsList) && <Slider mb="31px" inlineSlider slides={organizationsList} rtl={rtl} />
          )}
        </Flex>
      </AsyncComponent>
      <Flex justifyContent="center" width={['100%', '100%', 'auto', 'auto']}>
        <StyledButton
          onClick={() => {
            navigate(RouterUrl.AllOffers, { state: { offersView: 'map' } });
          }}
        >
          {t('home.allOffers')}
        </StyledButton>
      </Flex>
    </Flex>
  );
};
