import { useTranslation } from 'react-i18next';
import { Flex, H4, BodySmall, colors } from '@beauty/beauty-market-ui';
import InfoIcon from '../../../../assets/info-image.png';

export const NoAppointment = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%" mt="40px">
      <img src={InfoIcon} alt="no data" />
      <H4 width="260px" mt="24px" mb="8px" textAlign="center!important">
        {t('appointments.noData')}
      </H4>
      <BodySmall width="260px" textAlign="center!important" color={colors.grey.dark}>
        {t('appointments.noDataHint')}
      </BodySmall>
    </Flex>
  );
};
